import StorkClub from 'public/StorkClub.svg';
import Upgraded from 'public/Upgraded.svg';
import Halo from 'public/Halo.png';
import JDI from 'assets/images/JDI.svg';
import Pingboard from 'public/Pingboard.svg';
import clsx from 'clsx';

export default function CustomerLogoCloud({ className }) {
  return (
    <div className={clsx(className, 'bg-gray-100')}>
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        <p className="text-center text-base font-semibold text-gray-500">
          Trusted by these <span className="font-extrabold">tech savvy</span>{' '}
          businesses
        </p>
        <div className="mt-6 grid grid-cols-2 place-items-center gap-8 md:grid-cols-6 lg:grid-cols-5">
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img
              className="h-10 contrast-0 grayscale-0"
              src={StorkClub}
              alt="Stork Club"
            />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img
              className="h-10 contrast-0 grayscale-0"
              src={Halo}
              alt="Halo"
            />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img
              className="h-10 contrast-0 grayscale-0"
              src={Upgraded}
              alt="Upgraded"
            />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 md:col-start-2 lg:col-span-1">
            <img className="h-10 bg-[#808080] p-2 px-6" src={JDI} alt="JDI" />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 md:col-start-4 lg:col-span-1">
            <img
              className="h-10 contrast-0 grayscale-0"
              src={Pingboard}
              alt="Pingboard"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
