export const faqs = [
  {
    question: 'How long does it take to get set up?',
    answer:
      'If you are in Austin, San Antonio, or Seattle, we can deliver a laptop within 24 hours (our record is 2 hours). Otherwise, there may be a 1-5 business day shipping delay, but we’ll get you a tracking number right away.' +
      'For new organizations, we get Google Workspace, Slack, and 1Password, set up in minutes. The hardest part is picking a domain name. If you already have a domain name and an existing setup, we’ll walk through the onboarding together. Migrations take more time to perform smoothly. We won’t bill you until your employees are onboarded.',
  },
  {
    question: 'Can we use our existing machines?',
    answer:
      'If you have Apple computers that are 4 or less years old, we will integrate them into the offer. For older machines, we offer a trade-in program. We’ll deliver new machines, pick up the old ones, wipe them, give them a new home, and credit you for the trade-in value.\n' +
      'We also support employee Mac and iOS devices (called Bring Your Own Device, or BYOD)',
  },
  {
    question: 'Do you offer iPhones or iPads?',
    answer:
      'You can buy iPads from us and we offer them on our upgrade program. We can include basic management and support for them. We cannot sell you an iPhone, but we can include it on our offerings such as asset tracking, compliance, and support.',
  },
  {
    question: 'What if I’m not happy with the service?',
    answer:
      'We hand you all keys, credentials, and accounts and support you through the transition. Your laptops will be transitioned to a standard Apple Upgrade plan.\n' +
      'We don’t believe in lock in as a way to keep customers.',
  },
  {
    question: 'Do you support [insert other software]?',
    answer:
      'We work closely with the Apple Certified Consultants and will endeavour to find one who supports all your needs.',
  },
  {
    question: 'Do you support Windows?',
    answer:
      'No, sorry. We are passionate about IT, but we believe it should just work and get out of the way. Our experience is that Apple devices deliver the most productive experience for users.',
  },
  {
    question: 'Do you set up WiFi and printers?',
    answer:
      'No, but we are working on partnering with local installers and Apple Certified Consultants. Let us know if you need an on-prem installation.',
  },
];
