import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { AllInOneCTA } from 'components/CallsToAction';
import useTitle from 'hooks/useTitle';
import clsx from 'clsx';
import Link from 'components/Link';
//import { TbCircleCheck } from 'react-icons/tb';
import { WithACNsPill } from 'components/WithACNsPill';

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/user/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/user/year' },
];
const tiers = [
  {
    name: 'On & Offboarding',
    id: 'tier-free',
    href: '/users/sign_up?ref=pricing-free',
    useATag: true,
    price: { monthly: '$0', annually: '$0' },
    description: 'Offload device logistics & order management to us.',
    CTA: 'Sign up for free',
    features: [
      'Lease or buy as needed',
      'Trade-in',
      'Easy order & collect portal',
      'Fast delivery & recovery',
      'Collect, clean & redeploy',
      'Zero carbon shipping',
    ],
    mostPopular: false,
  },
  {
    name: 'TechOps Foundations',
    id: 'tier-techops',
    href: '/users/sign_up/?ref=pricing-techops',
    useATag: true,
    price: { monthly: '$19.99', annually: '$199.99' },
    description: 'Essentials for device management & compliance.',
    CTA: 'Subscribe now',
    features: [
      'Device management (MDM & ABM)',
      'Zero touch deployment',
      'SaaS provisioning & billing (Google Workspace, Slack, 1Password)',
      'includes "Onboarding & Offboarding"',
    ],
    mostPopular: true,
  },
  {
    name: 'TechOps Plus',
    id: 'tier-techops-plus',
    href: '/users/sign_up/?ref=pricing-techops-plus',
    useATag: true,
    price: { monthly: '$85', annually: '$980' },
    description: 'The essentials to provide full support to your team',
    pill: <WithACNsPill />,
    CTA: 'Sign up',
    features: [
      'All-day support in dedicated Slack team channel',
      'Device compliance & optimization',
      'Mobile device management',
      'Security software suite & management',
    ],
    mostPopular: false,
  },
  {
    name: 'Compliance',
    id: 'tier-compliance',
    href: '/users/sign_up/?ref=pricing-compliance',
    useATag: true,
    price: { monthly: '$150', annually: '$1700' },
    description: 'TechOps Plus with managed compliance services.',
    pill: <WithACNsPill />,
    CTA: 'Start onboarding',
    features: [
      'Unlimited support in dedicated Slack team channel',
      'SOC II, ISO, HIPPA, NIST',
      'Simplify compliance',
      'Reduce your risk',
      'Customized packages to fit your needs',
    ],
    mostPopular: false,
  },
];

export default function Pricing() {
  useTitle('Pricing | Equipped');
  const [frequency, setFrequency] = useState(frequencies[0]);
  const openIntercom = () => {
    window.Intercom('show');
  };

  return (
    <div id="pricing" className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">
            Predictable Pricing
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Pricing plans for teams of&nbsp;all&nbsp;sizes
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Choose the plan the meets your needs.{' '}
          <a className="link" onClick={openIntercom}>
            Contact us
          </a>{' '}
          if you are not sure about which plan to choose.
        </p>
        <div className="mt-16 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">
              Payment frequency
            </RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  clsx(
                    checked ? 'bg-indigo-600 text-white' : 'text-gray-500',
                    'cursor-pointer rounded-full px-2.5 py-1',
                  )
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={clsx(
                tier.mostPopular
                  ? 'ring-2 ring-indigo-600'
                  : 'ring-1 ring-gray-200',
                'rounded-3xl p-8',
              )}
            >
              <h3
                id={tier.id}
                className={clsx(
                  tier.mostPopular ? 'text-indigo-600' : 'text-gray-900',
                  'text-lg font-semibold leading-8',
                )}
              >
                {tier.name}
              </h3>
              <p
                className={`mt-4 ${
                  tier.pill ? 'mb-4' : ''
                } text-sm leading-6 text-gray-600`}
              >
                {tier.description}
              </p>
              {tier.pill || (
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-gray-900">
                  {tier.price[frequency.value]}
                </span>
                <span className="text-sm font-semibold leading-6 text-gray-600">
                  {frequency.priceSuffix}
                </span>
              </p>)}
              {tier.useATag ? (
                <a
                  href={tier.href}
                  aria-describedby={tier.id}
                  className={clsx(
                    tier.mostPopular
                      ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'
                      : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                    'mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
                  )}
                >
                  {tier.CTA}
                </a>
              ) : (
                <Link
                  href={tier.href}
                  aria-describedby={tier.id}
                  className={clsx(
                    tier.mostPopular
                      ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'
                      : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                    'mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
                  )}
                >
                  {tier.CTA}
                </Link>
              )}
              <ul
                role="list"
                className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-indigo-600"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <AllInOneCTA />
    </div>
  );
}
