import { dataImagePlaceholder } from './ImagePlaceholder';
import Link from './Link';

export default function Features() {
  return (
    <div className="scene my-28 flex flex-col space-y-32 xl:my-56">
      <div className="grid grid-cols-12 items-center justify-center lg:gap-x-20">
        <div className="col-span-12 text-left lg:order-2 lg:col-span-6 lg:ml-20">
          <h2 className="mb-5 xl:text-4xl">Save cash. Macs by subscription.</h2>
          <p className="mb-5 leading-6 sm:text-lg">
            MacBook Air and MacBook Pro subscriptions with upgrades built-in.
            Low monthly prices. 12 to 48 month plans. Keep your team equipped
            with the latest tech.
          </p>
          <Link
            className="inline-flex items-center justify-center text-lg font-bold text-blue-700 hover:text-blue-800"
            href="/apple-devices/"
          >
            Device-as-a-Service
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              width="24"
              height="24"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
              ></path>
            </svg>
          </Link>
        </div>
        <div className="col-span-12 pt-4 lg:order-1 lg:col-span-6">
          <img
            className="rounded-xl drop-shadow-lg"
            src="/circular-economy.svg"
          />
        </div>
      </div>
      <div className="grid grid-cols-12 items-center justify-center lg:gap-x-20">
        <div className="col-span-12 text-left lg:order-2 lg:col-span-6 lg:mr-20">
          <h2 className="mb-5 xl:text-4xl">
            Become SOC II compliant. We simplify the journey.
          </h2>
          <p className="mb-5 leading-6 sm:text-lg">
            No business wants to divert time and energy from their customers to
            getting compliant. But a compliance certification puts your
            customers at ease and unlocks new business opportunities. We can
            help take most of the load off from that journey.
          </p>
          <Link
            className="inline-flex items-center justify-center text-lg font-bold text-blue-700 hover:text-blue-800"
            href="/compliance/"
          >
            Get compliant with Equipped
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              width="24"
              height="24"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
              ></path>
            </svg>
          </Link>
        </div>
        <div className="col-span-12 rounded-xl bg-white p-5 drop-shadow-lg lg:order-3 lg:col-span-6">
          <img className="" src="/Compliance Screenshot.png" />
        </div>
      </div>
      <div className="grid grid-cols-12 items-center justify-center lg:gap-x-20">
        <div className="col-span-12 text-left lg:order-2 lg:col-span-6 lg:ml-20">
          <h2 className="mb-5 xl:text-4xl">
            Access the expertise you need on-demand.
          </h2>
          <p className="mb-5 leading-6 sm:text-lg">
            All-day, world-class support. And we hire and work with industry
            experts such as{' '}
            <a
              href="https://consultants.apple.com/"
              target="_blank"
              className="link"
            >
              Apple Consultants
            </a>{' '}
            so that you have access to the experts you need when you need them.
          </p>
          <Link
            className="inline-flex items-center justify-center text-lg font-bold text-blue-700 hover:text-blue-800"
            href="/tech-ops/"
          >
            Tech Ops & Support
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              width="24"
              height="24"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
              ></path>
            </svg>
          </Link>
        </div>
        <div className="col-span-12 rounded-xl bg-white p-4 py-5 drop-shadow-lg lg:order-1 lg:col-span-6">
          <img src="/Support.png" />
        </div>
      </div>
    </div>
  );
}
