import { useState, useEffect } from 'react';
import Markdown from 'components/Markdown';
import useTitle from 'hooks/useTitle';
import Link from 'components/Link';

export default function Doc() {
  useTitle('Doc | Equipped');
  const [pageURL, setPageURL] = useState(0);
  useEffect(() => {
    setPageURL(encodeURI(window.location.href));
  });
  return (
    <>
      <main>
        <div className="scene mb-10">
          <div className="mb-16 space-y-5 lg:mt-10">
            <h1 className="break-words text-left text-4xl lg:text-6xl">
              Equipped All-In-One is now generally available
            </h1>
            <div className="flex flex-row items-center justify-start">
              <img
                src="https://xsgames.co/randomusers/avatar.php?g=male"
                className="mr-3 h-12 w-12 rounded-3xl ring-4 ring-blue-100"
                alt="Ziad Sawalha"
              />
              <div className="flex flex-col items-start justify-center space-y-1">
                <p className="text-left text-sm text-gray-600">
                  <a
                    href="https://twitter.com/ziadsawalha"
                    rel="noopener"
                    target="_blank"
                    className="text-gray-600"
                  >
                    Ziad Sawalha
                  </a>
                </p>
                <p className="space-x-2 text-left text-sm text-gray-500">
                  <span>February 8, 2023</span>
                  <span>·</span>
                  <span>3 min read</span>
                </p>
              </div>
            </div>
          </div>

          <div className="container prose lg:prose-xl">
            <Markdown>
              {`
          Last updated on April 21, 2023.
          
          # Titelum non sequitor
          
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.

          Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus.

          # Titelum sequitas

          Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi.

          Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc,
          `}
            </Markdown>
          </div>
        </div>
        <hr className="my-10"></hr>
        <div className="scene">
          <p className="mb-3 font-bold">Share article</p>
          <div className="flex flex-row space-x-4">
            <a
              className="text-gray-900"
              href={'https://twitter.com/intent/tweet?text=' + pageURL}
              rel="noopener"
              target="_blank"
              alt="Share via Twitter"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="22"
                height="22"
                fill="currentColor"
              >
                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path>
              </svg>
            </a>
            <div
              className="active:jump cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(window.location.href); // won't work on lvh.me
              }}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="22"
                  height="22"
                  fill="currentColor"
                >
                  <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"></path>
                  <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"></path>
                </svg>
              </div>
            </div>
            <a
              className="text-gray-900"
              href={'mailto:?body=' + pageURL}
              rel="noopener"
              alt="Share via email"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="22"
                height="22"
                fill="currentColor"
              >
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"></path>
              </svg>
            </a>
            <a
              className="text-gray-900"
              href={
                'https://www.linkedin.com/shareArticle?mini=true&amp;url=' +
                pageURL
              }
              rel="noopener"
              target="_blank"
              alt="Share via Linkedin"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="22"
                height="22"
                fill="currentColor"
              >
                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path>
              </svg>
            </a>
          </div>
        </div>
        <div className="scene mb-10">
          <div className="w-full max-w-3xl py-5">
            <div className="mb-10">
              <div className="">
                <h5 className="mb-4">Related articles</h5>
                <div className="grid w-full grid-cols-1 space-y-3">
                  <div className="w-full rounded-lg border p-4 shadow-md transition-shadow hover:shadow-lg">
                    <Link
                      className="flex flex-row items-center justify-between text-gray-900 hover:no-underline"
                      href="/doc/related-article/"
                    >
                      <p className="break-words">
                        How to use Equipped to lease devices
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        width="24"
                        height="24"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                        ></path>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <Link
              href="/docs/"
              className="mb-5 flex flex-row font-bold text-gray-900"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="24"
                height="24"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                ></path>
              </svg>
              <p className="break-words">All articles</p>
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}
