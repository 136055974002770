import LeasingCTA from 'components/CallsToAction';
import AppleMulti from 'public/Multi-Product_MacBook_Pro_14-in_iPad_Pro_11-in_Apple_Pencil_2nd-gen_AirPods_Pro_2nd-Gen_iPhone_14_Pro_Max_iPhone_14_Pro_Apple_Watch_Series_8_Screen__USEN-1.webp';
import PartnerLogoCloud from 'components/PartnerLogoCloud';
import useTitle from 'hooks/useTitle';
import Benefits from 'components/Benefits';
import Features from 'components/Features';
import Link from 'components/Link';
import CustomerLogoCloud from 'components/CustomerLogoCloud';
import Testimonials from 'components/Testimonials';

export default function Home() {
  useTitle('Equipped | All things tech, one monthly fee.');
  return (
    <main className="overflow-hidden">
      <div className="scene flex flex-col">
        <div className="flex items-center lg:my-20">
          <div className="flex flex-row content-between items-center">
            <div className="mx-auto max-w-3xl py-10 text-center lg:mt-0 lg:pr-14 lg:text-left">
              <div className="pb-10">
                <h1 className="pb-5 text-4xl md:text-6xl xl:text-7xl">
                  All things tech, <br />
                  one monthly fee.{' '}
                  <span className="highlight-gradient">
                    <br />
                    It's that simple
                  </span>
                  .
                </h1>
                <div className="my-5 flex">
                  <Link
                    href="/blog/Leasing"
                    className="mx-auto flex flex-row items-center justify-start rounded-xl border border-blue-200 bg-blue-50 px-3 py-1 text-sm font-medium text-blue-800 hover:bg-blue-100 lg:mx-0"
                  >
                    <span className="hidden flex-row items-center justify-center lg:flex">
                      Blog{' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="mx-1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                        ></path>
                      </svg>
                    </span>
                    Pros & cons of leasing a Mac
                  </Link>
                </div>
                <p className="text-lg xl:text-2xl">
                  Equipped is the easiest way to get your team the tech they
                  need wherever they are, keep track of your assets and IT
                  spend, and keep your team fully equipped and productive.
                </p>
              </div>
              <div className="mx-auto mb-5 grid max-w-lg grid-cols-1 gap-3 text-center lg:mx-0 xl:grid-cols-2">
                <a
                  href="/users/sign_up"
                  className="btn-lg col-span-1 flex flex-row items-center justify-center rounded-lg bg-[#171717] px-5 py-3 text-lg font-semibold text-white"
                >
                  Sign up for free{' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    width="24"
                    height="24"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    ></path>
                  </svg>
                </a>
                <Link
                  href="/ACNs"
                  className="col-span-1 rounded-lg border border-gray-900 px-5 py-3 text-lg font-semibold hover:bg-gray-100"
                >
                  I'm a Consultant
                </Link>
              </div>
              <p className="text-sm text-gray-500">
                Free tier available · No credit card required
              </p>
            </div>
            <div className="hidden max-w-md lg:block xl:max-w-lg">
              <picture>
                <source srcSet={AppleMulti} media="(max-width: 400px)" />
                <source srcSet={AppleMulti} media="(min-width: 800px)" />
                <img
                  src={AppleMulti}
                  width="100%"
                  height="auto"
                  style={{ minWidth: '880px', height: '100%' }}
                  alt="Apple Devices"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
      <CustomerLogoCloud />
      <Features />
      <PartnerLogoCloud className="mt-12" />
      <Benefits />
      <Testimonials />
      <LeasingCTA />
    </main>
  );
}
