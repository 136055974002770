import { Fragment, useContext, useState, useRef } from 'react';
import { Dialog, Popover, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  ChatBubbleLeftRightIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { VscBlank } from 'react-icons/vsc';

import {
  ChevronDownIcon,
  PhoneIcon,
  // PlayCircleIcon,
} from '@heroicons/react/20/solid';
import { FcGoogle } from 'react-icons/fc';
import { FaApple } from 'react-icons/fa';
import { TbCertificate, TbCircleCheck } from 'react-icons/tb';
import { MdOutlinePowerSettingsNew } from 'react-icons/md';
import SessionContext from 'contexts/SessionContext';
import Link from 'components/Link';
import clsx from 'clsx';
import { WithACNsPill } from 'components/WithACNsPill';
import { getURLWithSubdomain } from 'utils/urls';
import SignOutLink from 'components/SignOutLink';

const products = [
  {
    name: 'Hardware Subscriptions',
    description:
      'Purchase, lease, or start a Mac Upgrade plan for your business',
    href: '/apple-devices',
    icon: FaApple,
  },
  {
    name: 'Software Suscriptions',
    description:
      'Google, Slack & other productivity tools bundled, managed and properly set up',
    href: '/software',
    icon: FcGoogle,
  },
  {
    name: 'Compliance',
    description: 'Get across that SOC II finish line',
    href: '/compliance/',
    icon: TbCertificate,
  },
  {
    name: 'Onboarding & Offboarding',
    description:
      "Deliver a great experience for your new hires. We'll handle all the logistics",
    href: '/onboarding/',
    icon: MdOutlinePowerSettingsNew,
  },
  {
    name: 'Tech Ops & Support',
    description:
      'Experts to set things up correctly and get you unstuck when you need it',
    href: '/tech-ops/',
    icon: ChatBubbleLeftRightIcon,
  },
];

const links = [
  {
    name: 'Pricing',
    href: '/pricing/',
    showUnderMoreInNav: false,
    showInMobileLinks: true,
    showInMainNav: true,
  },
  {
    name: 'Blog',
    href: '/blog/',
    showUnderMoreInNav: false,
    showInMobileLinks: true,
    showInMainNav: true,
  },
  {
    name: 'FAQs',
    href: '/faq/',
    showUnderMoreInNav: true,
    showInMobileLinks: true,
    showInMainNav: false,
  },
  {
    name: 'Documentation',
    href: '/docs/',
    showUnderMoreInNav: true,
    showInMobileLinks: true,
    showInMainNav: false,
  },
  {
    name: 'Support',
    href: '/contact/',
    showUnderMoreInNav: true,
    showInMobileLinks: true,
    showInMainNav: false,
  },
  {
    name: 'For ACNs',
    href: '/ACNs/',
    showUnderMoreInNav: true,
    showInMobileLinks: true,
    showInMainNav: false,
  },
  {
    name: 'For Individuals →',
    href: 'https://getupgraded.com/',
    showUnderMoreInNav: true,
    showInMobileLinks: true,
    showInMainNav: false,
  },
  {
    name: 'For Retailers →',
    href: 'https://upgraded.io/',
    showUnderMoreInNav: true,
    showInMobileLinks: true,
    showInMainNav: false,
  },
];

export default function Header({ className = '' }) {
  const { context } = useContext(SessionContext);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const productMenuRef = useRef(null); // useRef<HTMLButtonElement>(null)
  const moreMenuRef = useRef(null); // useRef<HTMLButtonElement>(null)
  const closePopOver = (ref) => {
    // We need to manually close it! 🤦🏻‍♂️
    // Ref: https://headlessui.com/react/popover#closing-popovers-manually
    ref?.current?.click();
  };
  const openIntercom = () => {
    window.Intercom('show');
  };

  const callsToAction = [
    {
      name: '', //"Watch demo",
      href: '#', //"https://placehold.co/1920x1080.mp4?text=Place+Equipped+Demo+Here",
      target: '_blank',
      icon: VscBlank, //PlayCircleIcon,
    },
    {
      name: 'Contact sales',
      href: 'https://meetings.hubspot.com/jason-kitchener',
      //onClick: openIntercom,
      icon: PhoneIcon,
    },
  ];
  return (
    <header className="bg-white">
      <nav
        className={
          className ||
          clsx(
            'mx-auto flex max-w-7xl items-center justify-between p-4 lg:justify-start lg:space-x-10 lg:px-8',
            import.meta.env.DEV ? 'top-30' : 'top-0',
          )
        }
        aria-label="Global"
      >
        <div className="flex">
          <Link href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Equipped</span>
            <img
              className="h-8 w-auto pl-2"
              src="/Equipped Primary Black.svg"
              alt="Equipped logo"
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          {context.flipperFlags.shop &&
            <div className="mr-2 flex-none justify-end">
              <Link
                href="/shop"
                className="relative inline-flex items-center gap-x-1.5 rounded-md bg-[#171717] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
              >
                <svg
                  className="-ml-0.5 h-5 w-5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M4 5V16H20V5H4ZM2 4.00748C2 3.45107 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44892 22 4.00748V18H2V4.00748ZM1 19H23V21H1V19Z" />
                </svg>
                Get a Mac
              </Link>
            </div>
          }

          <button
            id="hamburger"
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 hover:bg-gray-100"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-6">
          <Popover className="relative flex-none basis-1/4 rounded-md p-1.5 hover:bg-gray-100">
            <Popover.Button
              ref={productMenuRef}
              className="flex items-center gap-x-1 text-base font-medium leading-6 text-gray-600 focus-visible:outline-none"
            >
              Products
              <ChevronDownIcon
                className="h-5 w-5 flex-none text-gray-400 ui-open:rotate-180 ui-open:transform"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-20 mt-3 w-screen max-w-lg overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 lg:max-w-3xl">
                <div className="relative grid gap-6 bg-white p-3 sm:gap-6 sm:p-6 lg:grid-cols-2">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex gap-6 rounded-lg p-2 leading-6 hover:bg-gray-50"
                    >
                      <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg border bg-gray-50 drop-shadow-lg grayscale group-hover:bg-white group-hover:filter-none">
                        <item.icon
                          className="h-6 w-6 text-gray-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-auto">
                        <Link
                          href={item.href}
                          onClick={() => {
                            item.onClick ? item.onClick() : null;
                            closePopOver(productMenuRef);
                          }}
                          className="block text-base font-medium text-gray-900"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link>
                        <p className="mt-1 text-sm text-gray-600">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  ))}
                  <WithACNsPill
                    className="mb-2 ml-1 mt-4 pl-4 pt-2"
                    message="TechOps provided in partnership with members of the"
                  />
                </div>
                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {callsToAction.map((item) => (
                    <Link
                      key={item.name}
                      href={item.href}
                      onClick={() => {
                        item.onClick ? item.onClick() : null;
                        closePopOver(productMenuRef);
                      }}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    >
                      <item.icon
                        className="h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          {links
            .filter((link) => link.showInMainNav)
            .map((link) => (
              <Link
                key={link.name}
                href={link.href}
                className="flex-none basis-1/4 rounded-md p-1.5 text-base font-medium leading-6 text-gray-600 hover:bg-gray-100"
              >
                {link.name}
              </Link>
            ))}

          <Popover className="relative flex-none basis-1/4">
            <Popover.Button
              ref={moreMenuRef}
              className="flex items-center gap-x-1 rounded-md p-1.5 text-base font-medium leading-6 text-gray-600 hover:bg-gray-100 focus-visible:outline-none"
            >
              More
              <ChevronDownIcon
                className="h-5 w-5 flex-none text-gray-400 ui-open:rotate-180 ui-open:transform"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-20 mt-3 w-56 rounded-xl bg-white p-2 shadow-lg ring-1 ring-gray-900/5">
                {links
                  .filter((link) => link.showUnderMoreInNav)
                  .map((link) => (
                    <Link
                      key={link.name}
                      href={link.href}
                      onClick={() => {
                        link.onClick ? link.onClick() : null;
                        closePopOver(moreMenuRef);
                      }}
                      className="block rounded-lg px-3 py-2 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                    >
                      {link.name}
                    </Link>
                  ))}
              </Popover.Panel>
            </Transition>
          </Popover>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {context.user?.email ? (
            context.user?.accounts?.length === 1 ? (
              <a
                href={getURLWithSubdomain(context.user.accounts[0])}
                className="rounded-md p-1.5 text-base font-medium leading-6 text-gray-900 hover:bg-gray-100"
              >
                Dashboard <span aria-hidden="true">&rarr;</span>
              </a>
            ) : (
              <a
                href="/accounts"
                className="rounded-md p-1.5 text-base font-medium leading-6 text-gray-900 hover:bg-gray-100"
              >
                Dashboard <span aria-hidden="true">&rarr;</span>
              </a>
            )
          ) : (
            <>
              {context.flipperFlags.shop &&
                <div className="mr-2 flex-none justify-end">
                  <Link
                    href="/shop"
                    className="relative inline-flex items-center gap-x-1.5 rounded-md bg-[#171717] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                  >
                    <svg
                      className="-ml-0.5 h-5 w-5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M4 5V16H20V5H4ZM2 4.00748C2 3.45107 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44892 22 4.00748V18H2V4.00748ZM1 19H23V21H1V19Z" />
                    </svg>
                    Get a Mac on a monthly plan
                  </Link>
                </div>
              }
              <a
                href="/users/sign_in"
                className="rounded-md p-1.5 text-base font-medium leading-6 text-gray-900 hover:bg-gray-100"
              >
                Log in <span aria-hidden="true">&rarr;</span>
              </a>
            </>
          )}
        </div>
      </nav>

      {/* Mobile menu */}
      <Dialog
        id="hamburger"
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-20" />
        <Dialog.Panel
          style={{ maxHeight: '100vh' }}
          className="fixed inset-x-0 top-1 z-20 origin-top-right overflow-y-auto p-2 pb-6 transition motion-reduce:transition-none motion-reduce:hover:transform-none md:start-auto"
        >
          <div className="origin-top-right divide-y-2 divide-gray-50 rounded-lg border bg-white p-2 shadow-lg sm:ring-1 sm:ring-gray-900/10 md:max-w-md">
            <div className="flex items-center justify-between px-5 pb-4 pt-5">
              <Link
                href="/"
                className="-m-1.5 p-1.5 focus-visible:outline-none"
              >
                <span className="sr-only">Equipped</span>
                <img
                  className="h-8 w-auto"
                  src="/Equipped Primary Black.svg"
                  onClick={() => setMobileMenuOpen(false)}
                  alt="Equipped logo"
                />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700 hover:bg-gray-100"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="flow-root px-5 pt-6">
              <div className="-my-4">
                <div className="space-y-4 divide-y-2 divide-gray-50 py-6">
                  <div className="">
                    <nav className="grid gap-6">
                      {products.map((item) => (
                        <Link
                          key={item.name}
                          href={item.href}
                          onClick={() => setMobileMenuOpen(false)}
                          className="-m-3 flex items-center rounded-lg p-3 grayscale hover:bg-gray-50 hover:no-underline hover:filter-none"
                        >
                          <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-xl border bg-white shadow-md">
                            <item.icon
                              className="h-6 w-6 text-gray-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-4 text-base font-medium text-gray-900">
                            {item.name}
                          </div>
                        </Link>
                      ))}
                    </nav>
                  </div>
                  <div className="grid grid-cols-2 gap-5 py-4">
                    {links
                      .filter((link) => link.showInMobileLinks)
                      .map((link) => (
                        <Link
                          key={link.name}
                          href={link.href}
                          onClick={() => setMobileMenuOpen(false)}
                          className="block text-base font-medium leading-7 text-gray-900"
                        >
                          {link.name}
                        </Link>
                      ))}
                  </div>
                  <div className="mt-10">
                    {context.user?.email ? (
                      <>
                        <a
                          href="/accounts"
                          className="btn btn-primary flex w-full items-center justify-center"
                          onClick={() => setMobileMenuOpen(false)}
                        >
                          Your dashboard{' '}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="inline"
                            viewBox="0 0 16 16"
                            width="24"
                            height="24"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                            ></path>
                          </svg>
                        </a>
                        <p className="mt-5 flex flex-row items-center justify-center text-center text-base font-medium text-gray-600">
                          You are already logged in.{' '}
                          <SignOutLink className="link ml-4" />
                        </p>
                      </>
                    ) : (
                      <>
                        <a
                          href="/users/sign_up/?ref=navbar"
                          className="btn btn-primary flex w-full items-center justify-center"
                          onClick={() => setMobileMenuOpen(false)}
                        >
                          Start for free{' '}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="inline"
                            viewBox="0 0 16 16"
                            width="24"
                            height="24"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                            ></path>
                          </svg>
                        </a>
                        <p className="mt-5 flex flex-row items-center justify-center text-center text-base font-medium text-gray-600">
                          Already have an account?{' '}
                          <a href="/users/sign_in/" className="link ml-4">
                            Log in
                          </a>
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
