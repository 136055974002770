import React from 'react';
import { useEffect, useState } from 'react';
import Markdoc from '@markdoc/markdoc';
import stripIndent from 'strip-indent';
import clsx from 'clsx';

export default function Markdown({ className = '', children }) {
  const [content, setContent] = useState('');

  useEffect(() => {
    if (typeof children === 'string') {
      setContent(stripIndent(children));
    } else {
      console.log(typeof children);
      console.log(children);
    }
  }, [children]);

  function toMarkdown(doc) {
    const ast = Markdoc.parse(doc);
    const transformed = Markdoc.transform(ast);
    return Markdoc.renderers.react(transformed, React);
  }

  return (
    <div className={clsx('markdown', className)}>{toMarkdown(content)}</div>
  );
}
