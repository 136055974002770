import { useContext } from 'react';
import SessionContext from 'contexts/SessionContext';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';

const alertColor = {
  alert: 'red',
  notice: 'green',
  info: 'blue',
  success: 'green',
  warning: 'yellow',
  error: 'yellow',
  confetti: 'green',
};

const alertIcons = {
  alert: XCircleIcon,
  notice: CheckCircleIcon,
  info: ExclamationCircleIcon,
  success: CheckCircleIcon,
  warning: ExclamationTriangleIcon,
  error: ExclamationTriangleIcon,
  confetti: CheckCircleIcon,
};

export default function Alerts() {
  const context = useContext(SessionContext);

  function closeAlert(event) {
    let element = event.target;
    while (element.nodeName !== 'BUTTON') {
      element = element.parentNode;
    }
    element.parentNode.parentNode.parentNode.parentNode.classList.add('hidden');
  }

  const result = Object.entries(context.flash)
    .filter(([_, value]) => value !== null && value !== '')
    .filter(([key, _]) => ['notice', 'signed_out'].indexOf(key) < 0) // notice is just unnecessary noise. We also hide it in erb
    .map(([key, value], index) => {
      const Icon = alertIcons[key] || alertIcons['warning'];
      return (
        <div
          id={`flash_${key}`}
          key={index}
          className={`mb-2 rounded-md p-4 flash-${key} bg-${alertColor[key]}-50`}
          data-controller={`flash ${key == 'confetti' ? 'confetti' : ''}`}
        >
          <div className="flex">
            <div className="flex-shrink-0">
              <Icon
                className={`h-5 w-5 text-${alertColor[key]}-400`}
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className={`text-sm font-medium text-${alertColor[key]}-800`}>
                {Array.isArray(value) ? value.join('. ') : (value as string)}
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  onClick={(e) => closeAlert(e)}
                  className={`inline-flex rounded-md bg-${alertColor[key]}-50 p-1.5 text-${alertColor[key]}-500 hover:bg-${alertColor[key]}-100 focus:outline-none focus:ring-2 focus:ring-${alertColor[key]}-600 focus:ring-offset-2 focus:ring-offset-${alertColor[key]}-50`}
                >
                  <span className="sr-only">Dismiss</span>
                  <svg
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    })
    .filter((value) => typeof value !== 'undefined');
  return <div id="flash">{result}</div>;
}
