import { useState, useEffect } from 'react';
import Markdown from 'components/Markdown';
import TERMS from 'data/terms.md';
import useTitle from 'hooks/useTitle';

export default function Terms() {
  useTitle('Terms | Equipped');

  let [content, setContent] = useState('');

  useEffect(() => {
    fetch(TERMS)
      .then((res) => res.text())
      .then((md) => {
        setContent(md);
      });
  }, []);

  return (
    <Markdown className="scene prose mx-auto mt-12 max-w-none lg:prose-xl sm:mt-24">
      {content}
    </Markdown>
  );
}
