import useTitle from 'hooks/useTitle';

export default function Done() {
  useTitle('The Done Company');
  return (
    <div
      style={{ background: '#261C15' }}
      className="flex h-screen flex-col items-center text-white antialiased"
    >
      <Logo className="flex-0 mx-auto mt-36" />
      <h1 className="mt-24 w-3/4 text-center text-6xl font-semibold">
        The most sustainable way to be productive.
      </h1>
      <h2 className="mt-8 w-3/4 text-center text-3xl font-semibold">
        We help companies and individuals cut down the financial and
        environmental cost of their tech. Discover our solutions below.
      </h2>
    </div>
  );
}

export function Logo({ className }) {
  return (
    <svg
      width="270"
      height="129"
      viewBox="0 0 270 129"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.9457 39.5086H56.7925V12.4896H66.0113V0.000824335H31.7268V12.4896H40.9457V39.5086Z"
        fill="white"
      />
      <path
        d="M65.8616 39.5086H80.4431V26.1192C80.4431 22.997 82.0097 21.676 84.2391 21.676C86.4083 21.676 87.2518 22.7568 87.2518 24.9183V39.5086H101.833V20.9555C101.833 13.6304 98.6398 9.3674 91.2286 9.3674C84.6006 9.3674 81.5879 13.5103 80.3829 17.8934H80.3226C80.4377 11.9281 80.3226 5.96638 80.3226 9.15527e-05H65.8616V39.5086Z"
        fill="white"
      />
      <path
        d="M134.999 23.2371C134.999 14.0507 129.516 9.3674 118.671 9.3674C108.006 9.3674 101.257 14.3509 101.257 24.9784C101.257 33.0841 105.294 39.9889 118.671 39.9889C130.3 39.9889 134.939 35.1855 134.999 27.9805H122.828C122.768 30.2621 121.623 31.3428 119.635 31.3428C117.044 31.3428 116.019 29.9018 115.839 26.1792H134.999V23.2371ZM122.828 21.4959H116.019C116.381 18.0135 117.465 16.9327 119.635 16.9327C121.743 16.9327 122.828 18.1936 122.828 20.3551V21.4959Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.392 60.9622C84.8286 60.9622 81.8931 71.8352 81.934 83.013H65.5549C65.5346 77.5934 64.1972 72.4345 55.4901 72.4345V54.9888C63.3556 54.9568 71.0054 58.2117 72.0809 66.6609C72.0809 52.1291 89.3047 43.571 102.392 43.5165V60.9622Z"
        fill="#00FF66"
      />
      <path
        d="M105.921 83.025H124.72C139.603 83.025 145.869 76.7806 145.869 63.2111C145.869 50.9025 139.362 43.5173 123.696 43.5173H105.921V83.025ZM121.828 71.1367V55.4056H124.539C127.853 55.4056 129.962 57.8073 129.962 63.2111C129.962 68.9751 128.034 71.1367 124.72 71.1367H121.828Z"
        fill="white"
      />
      <path
        d="M162.968 83.5053C175.621 83.5053 180.923 77.0208 180.923 68.2546C180.923 59.4885 175.561 52.8838 162.968 52.8838C150.254 52.8838 144.952 59.4885 144.952 68.2546C144.952 77.0208 150.194 83.5053 162.968 83.5053ZM162.968 73.6584C160.618 73.6584 159.533 71.7971 159.533 68.1946C159.533 64.472 160.618 62.7308 162.968 62.7308C165.257 62.7308 166.342 64.472 166.342 68.1946C166.342 71.8572 165.197 73.6584 162.968 73.6584Z"
        fill="white"
      />
      <path
        d="M180.495 83.025H195.076V69.5155C195.076 66.5134 196.763 65.1925 198.872 65.1925C200.981 65.1925 201.885 66.3333 201.885 68.4348V83.025H216.466V64.472C216.466 57.4471 213.574 52.8838 205.862 52.8838C199.234 52.8838 196.221 56.9667 194.956 61.4098H194.835V53.3642H180.495V83.025Z"
        fill="white"
      />
      <path
        d="M249.634 66.7536C249.634 57.5671 244.151 52.8838 233.305 52.8838C222.64 52.8838 215.891 57.8673 215.891 68.4948C215.891 76.6005 219.928 83.5053 233.305 83.5053C244.934 83.5053 249.573 78.702 249.634 71.4969H237.462C237.402 73.7785 236.257 74.8593 234.269 74.8593C231.678 74.8593 230.654 73.4183 230.473 69.6956H249.634V66.7536ZM237.462 65.0124H230.654C231.015 61.5299 232.1 60.4492 234.269 60.4492C236.378 60.4492 237.462 61.71 237.462 63.8716V65.0124Z"
        fill="white"
      />
      <path
        d="M21.2686 116.812C35.4885 116.812 41.1524 109.667 41.1524 100.18V98.3189H28.3183V99.5197C28.3183 103.422 25.6671 104.924 22.7147 104.924C19.0392 104.924 16.388 102.642 16.388 96.5176C16.388 90.5134 18.9187 87.9916 22.7749 87.9916C25.7274 87.9916 28.3183 89.6128 28.3183 93.5756C28.3183 93.8157 28.3183 94.236 28.2581 94.7764H41.0922C41.1524 93.9358 41.1524 93.0352 41.1524 92.5549C41.1524 83.7887 35.7295 76.1033 21.2686 76.1033C7.53066 76.1033 -0.00109863 83.4885 -0.00109863 96.5176C-0.00109863 108.526 6.08456 116.812 21.2686 116.812Z"
        fill="white"
      />
      <path
        d="M58.3658 116.692C71.0191 116.692 76.3215 110.207 76.3215 101.441C76.3215 92.6749 70.9589 86.0703 58.3658 86.0703C45.6522 86.0703 40.3498 92.6749 40.3498 101.441C40.3498 110.207 45.5919 116.692 58.3658 116.692ZM58.3658 106.845C56.0159 106.845 54.9313 104.984 54.9313 101.381C54.9313 97.6584 56.0159 95.9172 58.3658 95.9172C60.6554 95.9172 61.74 97.6584 61.74 101.381C61.74 105.044 60.5952 106.845 58.3658 106.845Z"
        fill="white"
      />
      <path
        d="M75.8926 115.614H90.4741V102.702C90.4741 99.7599 91.7394 98.3789 93.4265 98.3789C94.9931 98.3789 95.656 99.3997 95.656 101.441V115.614H110.237V102.642C110.237 99.6999 111.503 98.3789 113.19 98.3789C114.696 98.3789 115.419 99.3997 115.419 101.501V115.614H130.001V97.5984C130.001 90.0331 126.928 86.0703 119.999 86.0703C114.576 86.0703 111.262 89.6728 110.117 94.2961H110.057C109.213 88.6521 106.02 86.0703 100.235 86.0703C94.5111 86.0703 91.6792 89.853 90.4139 94.5362H90.2933V86.5506H75.8926V115.614Z"
        fill="white"
      />
      <path
        d="M129.87 128.1H144.452V117.112C144.452 115.791 144.392 113.149 144.331 111.588H144.452C145.777 114.59 148.489 116.692 153.731 116.692C161.383 116.692 166.203 111.648 166.203 100.721C166.203 91.294 161.986 86.0703 154.153 86.0703C148.67 86.0703 145.838 88.2318 144.392 91.9544H144.331V86.5506H129.87V128.1ZM148.308 106.845C146.259 106.845 144.693 105.404 144.452 102.642V101.201C144.512 97.4783 146.139 95.9172 148.308 95.9172C150.477 95.9172 151.682 97.4783 151.682 101.261C151.682 105.164 150.477 106.845 148.308 106.845Z"
        fill="white"
      />
      <path
        d="M175.154 116.692C181.18 116.692 183.65 114.05 184.614 110.808H184.675C184.614 112.969 184.494 114.712 184.434 115.613H199.015V99.3997C199.015 91.6542 194.436 86.0703 182.144 86.0703C170.635 86.0703 166.116 90.4534 166.116 97.058C166.116 97.8386 166.177 98.6191 166.237 99.3396H178.408C178.348 98.8593 178.348 98.3789 178.348 98.2589C178.348 96.3375 179.312 95.3768 181.421 95.3768C183.53 95.3768 184.434 96.5176 184.434 98.439V100.841H174.612C167.803 100.841 165.213 104.203 165.213 108.526C165.213 112.309 167.321 116.692 175.154 116.692ZM180.999 109.607C179.433 109.607 178.468 108.766 178.468 107.385C178.468 106.004 179.252 105.344 181.059 105.344H184.434V106.244C184.373 108.346 182.988 109.607 180.999 109.607Z"
        fill="white"
      />
      <path
        d="M198.926 115.613H213.507V102.702C213.507 99.6999 215.194 98.3789 217.303 98.3789C219.412 98.3789 220.316 99.5197 220.316 101.621V115.613H234.897V97.6584C234.897 90.6335 232.005 86.0703 224.293 86.0703C217.665 86.0703 214.652 90.1532 213.387 94.5963H213.266V86.5506H198.926V115.613Z"
        fill="white"
      />
      <path
        d="M245.237 129C253.25 129 257.167 126.359 260.059 117.292L270.001 86.5506H256.745L253.009 101.861H252.829L248.49 86.5506H231.8L242.97 115.613H246.14C245.428 118.032 244.453 118.973 242.284 118.973C240.477 118.973 238.85 118.853 237.765 118.673V127.98C239.332 128.4 242.465 129 245.237 129Z"
        fill="white"
      />
    </svg>
  );
}
