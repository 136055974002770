import Brett from 'public/brett-elsmore.jpeg';
import StorkClub from 'public/StorkClub.svg';
import Yupin from 'public/yupin-lee.jpeg';
import Pingboard from 'public/Pingboard.svg';

export default function Testimonials() {
  return (
    <div className="scene my-28 xl:my-56">
      <div className="mx-auto w-full max-w-5xl">
        <h2 className="mb-5 text-center xl:text-4xl">
          Don't just take our word for it
        </h2>
        <div className="mb-10 mt-20 grid grid-cols-1 gap-y-5 lg:grid-cols-2 lg:gap-x-5">
          <div className="flex">
            <div
              id="storkclub"
              className="shadow-colored-2xl flex flex-col items-end justify-between rounded-t-3xl rounded-bl-3xl border p-5"
            >
              <p className="mb-10 inline-flex items-start justify-center">
                <span className="mr-1 text-gray-400">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="currentColor"
                    className="bi bi-quote"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"></path>
                  </svg>
                </span>
                <span className="text-lg">
                  <span>
                    I would say that stop wasting your time trying to support
                    your users. Just have Equipped do it for you because it{' '}
                    <strong>saves so much time!</strong>
                  </span>
                </span>
              </p>
              <p className="text- flex items-end justify-end text-right">
                <a
                  href="https://www.joinstorkclub.com/"
                  rel="noopener"
                  target="_blank"
                  className="font-bold text-gray-600"
                >
                  <img
                    src={Brett}
                    width="auto"
                    height="100%"
                    className="h-10 rounded-full"
                    alt="Brett Elsmore from Stork Club"
                  />
                </a>
              </p>
              <div>
                <p className="mb-2 mt-2 text-right text-gray-600">
                  Brett Elsmore
                </p>
                <p className="text- flex items-end justify-end text-right">
                  <a
                    href="https://www.joinstorkclub.com/"
                    rel="noopener"
                    target="_blank"
                    className="font-bold text-gray-600"
                  >
                    <img
                      src={StorkClub}
                      width="auto"
                      height="100%"
                      className="h-6 opacity-80"
                      alt="Strok Club"
                    />
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="flex">
            <div
              id="pingboard"
              className="shadow-colored-2xl flex flex-col items-end justify-between rounded-t-3xl rounded-bl-3xl border p-5"
            >
              <p className="mb-10 inline-flex items-start justify-center">
                <span className="mr-1 text-gray-400">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="currentColor"
                    className="bi bi-quote"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"></path>
                  </svg>
                </span>
                <span className="text-lg">
                  <span>
                    I’m the sole person responsible for device management and it
                    truly has{' '}
                    <strong>helped take this task off my plate!</strong>
                  </span>
                </span>
              </p>
              <p className="text- mt-auto flex items-end justify-end text-right">
                <a
                  href="https://www.pingboard.com/"
                  rel="noopener"
                  target="_blank"
                  className="font-bold text-gray-600"
                >
                  <img
                    src={Yupin}
                    width="auto"
                    height="100%"
                    className="h-10 w-10 rounded-full object-cover"
                    alt="Yupin Lee"
                  />
                </a>
              </p>
              <div>
                <p className="mb-2 mt-2 text-right text-gray-600">Yupin Lee</p>
                <p className="text- flex items-end justify-end text-right">
                  <a
                    href="https://www.pingboard.com/"
                    rel="noopener"
                    target="_blank"
                    className="font-bold text-gray-600"
                  >
                    <img
                      src={Pingboard}
                      width="auto"
                      height="100%"
                      className="h-7 opacity-80"
                      alt="Pingboard"
                    />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
