import { useState, useEffect } from 'react';
import Markdown from 'components/Markdown';
import SECURITY from 'data/security.md';
import useTitle from 'hooks/useTitle';

export default function Security() {
  useTitle('Security | Equipped');
  let [content, setContent] = useState('');

  useEffect(() => {
    fetch(SECURITY)
      .then((res) => res.text())
      .then((md) => {
        setContent(md);
      });
  }, []);

  return (
    <>
      <div className="prose mx-auto mt-24 lg:prose-xl">
        <Markdown>{content}</Markdown>
      </div>
    </>
  );
}
