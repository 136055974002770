import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import useTitle from 'hooks/useTitle';
import Link from 'components/Link';

export default function Contact() {
  useTitle('Contact | Equipped');
  function openIntercom() {
    window.Intercom('show');
  }
  return (
    <>
      <div className="mx-auto mb-20 flex max-w-3xl flex-col">
        <div className="mx-auto flex max-w-4xl flex-col items-center justify-center py-20 text-center">
          <h1 className="mb-5 text-5xl md:text-6xl xl:text-7xl">
            How can we help?
          </h1>
          <p className="xl:text-2xl">
            {' '}
            In case you need help from a friendly human, simply chat us here or
            send us a message via email.
          </p>
        </div>

        <div className="mx-auto grid w-full grid-cols-1 gap-x-5 gap-y-5 py-5 lg:grid-cols-2">
          <div className="col-span-1">
            <a
              rel="noopener"
              className="mx-auto flex h-full w-full flex-col items-center justify-center space-y-3 rounded-xl border py-20 text-gray-900 shadow-md transition-shadow hover:no-underline hover:shadow-lg"
              href="mailto:support@tryequipped.com"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="28"
                height="28"
                fill="currentColor"
                className="mb-3"
              >
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"></path>
              </svg>
              <p className="text-xl font-normal">Send us an email</p>
            </a>
          </div>
          <div className="col-span-1">
            <a
              rel="noopener"
              className="mx-auto flex h-full w-full flex-col items-center justify-center space-y-3 rounded-xl border py-20 text-gray-900 shadow-md transition-shadow hover:no-underline hover:shadow-lg"
              onClick={openIntercom}
            >
              <ChatBubbleLeftRightIcon className="mb-3 h-7 w-7" />
              <p className="text-xl font-normal">Chat with at us</p>
            </a>
          </div>
          <div className="col-span-1">
            <Link
              rel="noopener"
              className="mx-auto flex h-full w-full flex-col items-center justify-center space-y-3 rounded-xl border py-20 text-gray-900 shadow-md transition-shadow hover:no-underline hover:shadow-lg"
              href="/docs/"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="28"
                height="28"
                fill="currentColor"
                className="mb-3"
              >
                <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"></path>
                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"></path>
              </svg>
              <p className="text-xl font-normal">Documentation</p>
            </Link>
          </div>
          <div className="col-span-1">
            <Link
              rel="noopener"
              className="mx-auto flex h-full w-full flex-col items-center justify-center space-y-3 rounded-xl border py-20 text-gray-900 shadow-md transition-shadow hover:no-underline hover:shadow-lg"
              href="/faq/"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="28"
                height="28"
                fill="currentColor"
                className="mb-3"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"></path>
              </svg>
              <p className="text-xl font-normal">Frequently Asked Questions</p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
