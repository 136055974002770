import Markdown from 'components/Markdown';
import useTitle from 'hooks/useTitle';

export default function About() {
  useTitle('About | Equipped');
  return (
    <>
      <div className="mx-auto flex max-w-4xl flex-col items-center justify-center py-20 text-center">
        <h1 className="mb-5 text-5xl md:text-6xl xl:text-7xl">
          <span className="highlight-gradient">Equip your team.</span>
        </h1>
        <p className="text-2xl font-medium xl:text-4xl">
          That's what we help you do.
        </p>
      </div>

      <div className="container prose mx-auto px-4 lg:prose-xl">
        <Markdown>
          {`
            Equipped started because we believed too many great minds have spent counter-productive hours setting up the basics of their IT systems.

            Equipped gets devices to staff, ensures the productivity software they need is turned on and properly configured, and stands by to help if any of it gets in the way of you getting your job done.

            We also believe that with the right people - properly informed, trained, and motivated - we can build high quality tools that are delightful to use.

            Since our launch in late 2022, Equipped has grown into a team of 7 supporting 10 companies (as of April 2023, when we wrote this). We serve customers both big and small, while remaining fully self-funded.

            Have any questions or feedback? You can always reach out to us directly [via email](mailto:hello@tryequipped.com?subject=I%20was%20reading%20your%20About%20page...).

            John & Ziad
            
            Founders
          `}
        </Markdown>
      </div>
    </>
  );
}
