import { Link } from 'react-router-dom';
import useTitle from 'hooks/useTitle';

type ErrorPageHints = {
  code?: number;
  name?: string;
  suggestion?: string;
};
type ErrorPageProps = {
  error?: ErrorPageHints;
  retryUrl?: string;
};

export default function ErrorPage(props: ErrorPageProps) {
  useTitle('Error | Equipped');
  const { error = {}, retryUrl } = props;
  return (
    <>
      <div className="min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main className="sm:flex">
            <p className="text-4xl font-bold tracking-tight text-indigo-600 sm:text-5xl">
              {error.code || 'Error'}
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  {error.name || 'Something went wrong'}
                </h1>
                <p className="mt-1 text-base text-gray-500">
                  {error.suggestion ||
                    'Please check the URL in the address bar and try again.'}
                </p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                {retryUrl ? (
                  <Link
                    to={retryUrl}
                    className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Try again?
                  </Link>
                ) : (
                  <Link
                    to="/"
                    className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Go back home
                  </Link>
                )}

                <a
                  href="mailto://support@tryequipped.com"
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Contact support
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
