import useTitle from 'hooks/useTitle';
import Link from 'components/Link';
import allDocs from 'data/docs.md';
import { useEffect, useState } from 'react';
import Markdown from 'components/Markdown';
import { AllInOneCTA } from 'components/CallsToAction';

const docs = [
  {
    title: 'Announcing Equipped',
    date: 'September 1, 2022',
    description:
      "We've decided to focus our efforts on building Equipped. We're excited to share more about our new product soon.",
    url: '/docs/launch/',
  },
  {
    title: ' Apple Authorized!',
    date: 'November 7, 2022',
    description:
      "We've been authorized by Apple to reseller Apple devices to businesses in North America.",
    url: '/docs/Apple/',
  },
  {
    title: '1Password Partner',
    date: 'December 20, 2022',
    description:
      "We've been authorized by 1Password to be part of the 1Password Partner Program. We can now resell and bundle 1Password in our offers.",
    url: '/docs/1Password/',
  },
  {
    title: 'Google Cloud Partner Advantage',
    date: 'December 21, 2022',
    description:
      "We've been approved by Google to be part of the Google Cloud Partner Advantage. We can now resell Google Workspace to our customers.",
    url: '/docs/Google/',
  },
  {
    title: 'Vanta MSP Program membership',
    date: 'March 9, 2023',
    description:
      "We've been approved by Vanta to be part of their MSP program. We can now resell and bundle Vanta on our products.",
    url: '/docs/anta/',
  },
  {
    title: 'Upgraded in the US!',
    date: 'April 16, 2023',
    description:
      'Our sister company, Upgraded, has now launched in the US. Get the latest tech for your business, with no upfront costs.',
    url: '/docs/Upgraded/',
  },
  {
    title: 'Done, done, and done!',
    date: 'April 28, 2023',
    description:
      "We're announcing the Upgraded and Equipped are members of The Done Group of companies.",
    url: '/docs/TheDoneCompany/',
  },
  {
    title: 'What is MDM or ABM? And why do I need it?',
    date: 'April 24, 2023',
    description:
      "We're announcing the Upgraded and Equipped are members of The Done Group of companies.",
    url: '/docs/MDM/',
  },
];

export default function Docs() {
  useTitle('Docs | Equipped');

  let [content, setContent] = useState('');
  useEffect(() => {
    fetch(allDocs)
      .then((res) => res.text())
      .then((md) => {
        setContent(md);
      });
  }, []);

  return (
    <>
      <div className="scene">
        <div className="mx-auto mb-20 flex max-w-3xl flex-col">
          <div className="mx-auto flex max-w-4xl flex-col items-center justify-center py-20 text-center">
            <h1 className="mb-5 text-4xl md:text-6xl xl:text-7xl">
              Equipped Documentation
            </h1>
            <p className="xl:text-2xl">
              Learn more about tech ops best practices, how to improve staff
              productivity and user experience and how to use our products and
              services effectively.
            </p>
          </div>

          <div className="mb-20">
            <Markdown>{content}</Markdown>
          </div>

          <ul className="mx-auto grid hidden w-full max-w-4xl list-none grid-cols-1 gap-y-10 py-5">
            {docs.map((doc, index) => (
              <li key={index}>
                <div className="shadow-colored-2xl rounded-xl border p-6 transition-shadow">
                  <Link
                    className="flex h-full flex-col items-start justify-between text-gray-900 hover:no-underline"
                    href={doc.url}
                  >
                    <h2 className="mb-5 break-words text-xl lg:text-3xl">
                      {doc.title}
                    </h2>
                    <p className="mb-7 break-words text-gray-500">
                      {doc.description}
                    </p>
                    <p className="text-gray-500">{doc.date}</p>
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <AllInOneCTA />
    </>
  );
}
