import clsx from 'clsx';

export default function TopBar({ className }) {
  return (
    <div
      className={clsx(
        className,
        'flex gap-2 bg-gray-800 px-4 text-sm italic text-gray-300 max-sm:justify-center',
      )}
    >
      <span>
        <a className="hidden sm:inline-block" href="#">
          Equipped serves businesses
        </a>
      </span>
      <span className="ml-auto hidden sm:inline-block">Services for:</span>
      <span className="rounded-md px-2 hover:bg-gray-700 max-sm:ml-auto">
        <a href="/">Businesses</a>
      </span>
      |
      <span className="rounded-md px-2 hover:bg-gray-700">
        <a href="https://getupgraded.com">Individuals</a>
      </span>
      |
      <span className="rounded-md px-2 hover:bg-gray-700">
        <a href="https://upgraded.io">Retailers</a>
      </span>
      |
      <span className="rounded-md px-2 hover:bg-gray-700">
        <a href="/ACNs">ACNs</a>
      </span>
    </div>
  );
}
