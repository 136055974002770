import useTitle from 'hooks/useTitle';
import Link from 'components/Link';

const partners = [
  {
    title: ' Apple Authorized Reseller',
    description:
      "We've been authorized by Apple to reseller Apple devices to businesses in North America.",
    url: '/partners/Apple/',
  },
  {
    title: '1Password Partner',
    description:
      "We've been authorized by 1Password to be part of the 1Password Partner Program. We can now resell and bundle 1Password in our offers.",
    url: '/partners/1Password/',
  },
  {
    title: 'Google Cloud Partner Advantage',
    description:
      "We've been approved by Google to be part of the Google Cloud Partner Advantage. We can now resell Google Workspace to our customers.",
    url: '/partners/Google/',
  },
  {
    title: 'Vanta MSP Program membership',
    description:
      "We've been approved by Vanta to be part of their MSP program. We can now resell and bundle Vanta on our products.",
    url: '/partners/Vanta/',
  },
  {
    title: 'The ✓ Done Company',
    description:
      "We're announcing the Upgraded and Equipped are members of The Done Group of companies.",
    url: '/partners/TheDoneCompany/',
  },
];

export default function Partners() {
  useTitle('Partners | Equipped');
  return (
    <>
      <div className="scene mb-10">
        <div className="mx-auto mb-20 flex max-w-3xl flex-col">
          <div className="mx-auto flex max-w-4xl flex-col items-center justify-center py-20 text-center">
            <h1 className="mb-5 text-5xl md:text-6xl xl:text-7xl">
              Equipped Partnerships
            </h1>
            <p className="xl:text-2xl">
              Vendors and suppliers we proudly work with
            </p>
          </div>

          <ul className="mx-auto grid w-full max-w-4xl list-none grid-cols-1 gap-y-10 py-5">
            {partners.map((doc, index) => (
              <li key={index}>
                <div className="shadow-colored-2xl rounded-xl border p-6 transition-shadow">
                  <Link
                    className="flex h-full flex-col items-start justify-between text-gray-900 hover:no-underline"
                    href={doc.url}
                  >
                    <h2 className="mb-5 break-words text-xl lg:text-3xl">
                      {doc.title}
                    </h2>
                    <p className="mb-7 break-words text-gray-500">
                      {doc.description}
                    </p>
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
