import {
  ArrowPathIcon,
  FingerPrintIcon,
  ShieldCheckIcon,
  ChatBubbleLeftRightIcon,
} from '@heroicons/react/24/outline';
import Pricing from 'pages/Pricing'; //TODO: fix
import Hero from './Hero';
import PartnerLogoCloud from './PartnerLogoCloud';
import HubspotContactForm from './HubspotContactForm';
import { faqs } from 'data/FAQs';
import useTitle from 'hooks/useTitle';

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
];
const features = [
  {
    name: 'Compliance',
    description:
      'We take you and your staff across the SOC II compliance line.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Leasing',
    description:
      'Apple laptops on monthly subscription. Upgrade every 36 months. Trade in your old ones for cash.',
    icon: ArrowPathIcon,
  },
  {
    name: 'All-day Support',
    description:
      'That small tech problem that used to take up your whole day? Give it to us. Done.',
    icon: ChatBubbleLeftRightIcon,
  },
  {
    name: 'Advanced security',
    description:
      'Arcu egestas dolor vel iaculis in ipsum mauris. Tincidunt mattis aliquet hac quis. Id hac maecenas ac donec pharetra eget.',
    icon: FingerPrintIcon,
  },
];
const tiers = [
  {
    name: 'Freelancer',
    id: 'tier-freelancer',
    href: '#',
    priceMonthly: '$24',
    description: 'The essentials to provide your best work for clients.',
    features: [
      '5 products',
      'Up to 1,000 subscribers',
      'Basic analytics',
      '48-hour support response time',
    ],
    mostPopular: false,
  },
  {
    name: 'Startup',
    id: 'tier-startup',
    href: '#',
    priceMonthly: '$32',
    description: 'A plan that scales with your rapidly growing business.',
    features: [
      '25 products',
      'Up to 10,000 subscribers',
      'Advanced analytics',
      '24-hour support response time',
      'Marketing automations',
    ],
    mostPopular: true,
  },
  {
    name: 'Enterprise',
    id: 'tier-enterprise',
    href: '#',
    priceMonthly: '$48',
    description: 'Dedicated support and infrastructure for your company.',
    features: [
      'Unlimited products',
      'Unlimited subscribers',
      'Advanced analytics',
      '1-hour, dedicated support response time',
      'Marketing automations',
    ],
    mostPopular: false,
  },
];

export default function HomeTest() {
  useTitle('Home | React | Equipped');
  const openIntercom = () => {
    Intercom('show');
  };
  return (
    <main className="isolate">
      <Hero />
      {/* Pricing section */}
      <Pricing />
      <PartnerLogoCloud />

      {/* Testimonial section */}
      <div className="mx-auto mt-32 max-w-7xl sm:mt-56 sm:px-6 lg:px-8">
        <div className="relative overflow-hidden bg-gray-900 px-6 py-20 shadow-xl sm:rounded-3xl sm:px-10 sm:py-24 md:px-12 lg:px-20">
          <img
            className="absolute inset-0 h-full w-full object-cover brightness-150 saturate-0"
            src="https://images.unsplash.com/photo-1601381718415-a05fb0a261f3?ixid=MXwxMjA3fDB8MHxwcm9maWxlLXBhZ2V8ODl8fHxlbnwwfHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1216&q=80"
            alt="People being productive"
          />
          <div className="absolute inset-0 bg-gray-900/90 mix-blend-multiply" />
          <svg
            viewBox="0 0 1097 845"
            aria-hidden="true"
            className="absolute -left-80 -top-56 w-[68.5625rem] transform-gpu blur-3xl"
          >
            <path
              fill="url(#68eb76c4-2bc9-4928-860e-70adf05719f4)"
              fillOpacity=".45"
              d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
            />
            <defs>
              <linearGradient
                id="68eb76c4-2bc9-4928-860e-70adf05719f4"
                x1="1097.04"
                x2="-141.165"
                y1=".22"
                y2="363.075"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#776FFF" />
                <stop offset={1} stopColor="#FF4694" />
              </linearGradient>
            </defs>
          </svg>
          <svg
            viewBox="0 0 1097 845"
            aria-hidden="true"
            className="hidden md:absolute md:bottom-16 md:left-[50rem] md:block md:w-[68.5625rem] md:transform-gpu md:blur-3xl"
          >
            <path
              fill="url(#68eb76c4-2bc9-4928-860e-70adf05719f4)"
              fillOpacity=".25"
              d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
            />
          </svg>
          <div className="relative mx-auto max-w-2xl lg:mx-0">
            <img
              className="h-12 w-auto"
              src="https://tailwindui.com/img/logos/workcation-logo-white.svg"
              alt=""
            />
            <figure>
              <blockquote className="mt-6 text-lg font-semibold text-white sm:text-xl sm:leading-8">
                <p>
                  “Amet amet eget scelerisque tellus sit neque faucibus non
                  eleifend. Integer eu praesent at a. Ornare arcu gravida
                  natoque erat et cursus tortor consequat at. Vulputate gravida
                  sociis enim nullam ultricies habitant malesuada lorem ac.”
                </p>
              </blockquote>
              <figcaption className="mt-6 text-base text-white">
                <div className="font-semibold">Judith Black</div>
                <div className="mt-1">CEO of Tuple</div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>

      {/* FAQs */}
      <div className="mx-auto max-w-2xl divide-y divide-gray-900/10 px-6 pb-8 sm:pb-24 sm:pt-12 lg:max-w-7xl lg:px-8 lg:pb-32">
        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
          Frequently asked questions
        </h2>
        <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
          {faqs.map((faq) => (
            <div key={faq.id} className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                {faq.question}
              </dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">
                  {faq.answer}
                </p>
              </dd>
            </div>
          ))}
        </dl>
      </div>

      {/* CTA section */}
      <div className="relative -z-10 mt-32 px-6 lg:px-8">
        <div className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 transform-gpu justify-center overflow-hidden blur-3xl sm:bottom-0 sm:right-[calc(50%-6rem)] sm:top-auto sm:translate-y-0 sm:transform-gpu sm:justify-end">
          <svg
            viewBox="0 0 1108 632"
            aria-hidden="true"
            className="w-[69.25rem] flex-none"
          >
            <path
              fill="url(#191ef669-4d29-44ea-9496-5d65eb27150d)"
              fillOpacity=".25"
              d="M235.233 229.055 57.541 310.091.83.615l234.404 228.44L555.251 83.11c-65.036 115.261-134.286 322.756 109.01 230.655C968.382 198.638 1031-19.583 1092.23 172.304c48.98 153.51-34.51 321.107-82.37 385.717L810.952 307.442 648.261 631.576 235.233 229.055Z"
            />
            <defs>
              <linearGradient
                id="191ef669-4d29-44ea-9496-5d65eb27150d"
                x1="1220.59"
                x2="-85.053"
                y1="198.898"
                y2="-7.05"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9089FC" />
                <stop offset={1} stopColor="#FF80B5" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Boost your productivity.
            <br />
            Start using Equipped today.
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
            We have a few more slots available for companies looking for any of:
            SOC II compliance, Mac leasing & support, or our{' '}
            <a className="link" href="/all-in-one">
              All-In-One
            </a>{' '}
            offer. Please fill this form (or{' '}
            <a className="link" onClick={openIntercom}>
              chat with us
            </a>
            ) and we'll get back to you promptly
          </p>
          <div className="relative mt-10 items-center justify-stretch gap-x-6 overflow-auto">
            <HubspotContactForm
              region="na1"
              portalId="23711572"
              formId="88537438-0306-40b0-8c1b-26402ff4433b"
            />
          </div>
        </div>
        <div className="absolute left-1/2 right-0 top-full -z-10 hidden -translate-y-1/2 transform-gpu overflow-hidden blur-3xl sm:block">
          <svg
            viewBox="0 0 1155 678"
            aria-hidden="true"
            className="w-[72.1875rem]"
          >
            <path
              fill="url(#23e1b96e-c791-45fa-975a-a04d29498207)"
              fillOpacity=".3"
              d="M317.219 518.975 203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079Z"
            />
            <defs>
              <linearGradient
                id="23e1b96e-c791-45fa-975a-a04d29498207"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9089FC" />
                <stop offset={1} stopColor="#FF80B5" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </main>
  );
}
