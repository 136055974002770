import { useContext } from 'react';
import SessionContext from 'contexts/SessionContext';

export default function InvisibleCaptcha({ honeypotValue, setHoneypotValue }) {
  const context = useContext(SessionContext);
  const styleName = `title_dj387sg7`; //`title_${Date.now()}`, but that breaks SSR hydration
  return (
    <div className={styleName}>
      <style media="screen">{`.${styleName} {display:none;}`}</style>
      <label htmlFor={`email_signup_${context.honeypot}`}></label>
      <input
        type="text"
        name={`email_signup[${context.honeypot}]`}
        id={`email_signup_${context.honeypot}`}
        autoComplete="off"
        tabIndex={-1}
        value={honeypotValue}
        onChange={(e) => setHoneypotValue(e.target.value)}
      />
      <input
        type="hidden"
        id="spinner"
        name="spinner"
        value={context.spinner}
        autoComplete="off"
      />
    </div>
  );
}
