import { useState, useEffect } from 'react';
import Markdown from 'components/Markdown';
import PRIVACY from 'data/privacy.md';
import useTitle from 'hooks/useTitle';

export default function Privacy() {
  useTitle('Privacy | Equipped');
  let [content, setContent] = useState('');

  useEffect(() => {
    fetch(PRIVACY)
      .then((res) => res.text())
      .then((md) => {
        setContent(md);
      });
  }, []);

  return (
    <Markdown className="scene prose mx-auto mt-24 max-w-none lg:prose-xl">
      {content}
    </Markdown>
  );
}
