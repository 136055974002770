import useTitle from 'hooks/useTitle';
import AssetTrackingCTA from 'components/CallsToAction';
import Markdown from 'components/Markdown';
import complianceReadyMacs from 'data/compliance.md';
import { useEffect, useState } from 'react';

export default function Compliance() {
  useTitle('Compliance | Equipped');

  let [content, setContent] = useState('');
  useEffect(() => {
    fetch(complianceReadyMacs)
      .then((res) => res.text())
      .then((md) => {
        setContent(md);
      });
  }, []);

  return (
    <main>
      <div className="scene">
        <div className="mx-auto">
          <div className="flex flex-col items-center justify-center py-20 text-center lg:mt-14">
            <h2 className="mb-4 text-2xl font-semibold leading-7 text-indigo-700">
              Preparing for
            </h2>
            <h1 className="mb-5 text-4xl md:text-5xl lg:text-6xl">
              Compliance Certification?
            </h1>
            <p className="mb-10 text-lg text-gray-600">
              Our systems will check a number of compliance entries for you. For
              the rest, we bring in and work with partners to make it as simple
              for you to get certified.
            </p>
          </div>
          <div className="mb-20">
            <Markdown>{`
            ## Equipped Compliance
            Introducing Equipped Compliance, the comprehensive solution for businesses seeking to optimize their security and compliance standards. In today's highly regulated environment, it is essential for organizations to maintain robust policies and practices to ensure data protection and regulatory compliance. Equipped Compliance offers a complete package that incorporates industry-leading tools and expert guidance to help your company meet and exceed the stringent requirements of a SOC II audit. With our product, you can confidently navigate the complex landscape of security and compliance, safeguarding your organization's reputation and facilitating growth.

            ## Compliance-Ready Macs
            Equipped's Compliance package includes an Apple MacBook Pro or Air with device management enabled. This enables centralized control and configuration of all your company's Apple devices, ensuring that they adhere to your organization's security policies and best practices. In tandem with the device management system, we offer Vanta, a cutting-edge security and compliance automation platform that streamlines the process of preparing for a SOC II audit. Vanta simplifies continuous monitoring and reporting of your security controls, making it easier than ever to maintain the highest standards of data protection and compliance.

            ## Google Workspace Enterprise
            Equipped Compliance also includes Google Workspace Enterprise edition, which boasts advanced security features, such as content filtering, to help you manage and protect your organization's data more effectively. With content filtering enabled, you can monitor and control the flow of sensitive information within your organization, reducing the risk of unauthorized access or data breaches. This powerful suite of tools empowers your team to work securely and efficiently while ensuring the highest levels of compliance.

            ## Hands-on Guidance until you get your certificate
            What sets Equipped Compliance apart is the personal guidance provided by our team of experts in developing policies to pass a SOC II audit. Our professionals work closely with your organization to identify potential vulnerabilities, develop comprehensive security and compliance policies, and ensure that your team is well-prepared for the audit process. With Equipped Compliance, you not only gain access to top-of-the-line tools but also benefit from invaluable insights and expertise that can elevate your organization's security posture and help you achieve SOC II compliance with confidence.
            `}</Markdown>
          </div>

          <div className="mb-20 rounded-xl border p-6 drop-shadow">
            <Markdown>{content}</Markdown>
          </div>
        </div>
      </div>
      <AssetTrackingCTA />
    </main>
  );
}
