import clsx from 'clsx';

export default function PartnerLogoCloud({ className }) {
  return (
    <div className={clsx(className, 'mx-auto max-w-7xl px-6 lg:px-8')}>
      <p className="mb-7 text-center text-lg font-bold text-gray-600">
        Get more done with best-in-class productivity tools
      </p>
      <div className="sm:w-max-lg mx-auto grid max-w-lg grid-cols-1 items-center gap-x-8 gap-y-12 sm:grid-cols-4 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-6">
        <img
          className="col-span-1 w-full object-contain pt-1 sm:col-span-2 sm:max-h-14"
          src="/apple-authorized-reseller.jpg"
          alt="Apple Authorized Reseller"
          width={158}
          height={48}
        />
        <img
          className="col-span-2 max-h-12 w-full object-contain pt-4 invert lg:col-span-1 lg:scale-125"
          src="/Google/Wordmark Reverse/PNG/Google_Workspace_750x96px_wht_lockup_Google_Product_624x96px_clr.png"
          alt="Google Workspace"
          width={158}
          height={48}
        />
        <img
          className="col-span-2 max-h-12 w-full scale-75 object-contain pt-4 lg:col-span-1"
          src="/Vanta_Logos/png/vanta-black.png"
          alt="Vanta"
          width={158}
          height={48}
        />
        <img
          className="col-span-2 max-h-12 w-full scale-90 object-contain pt-5 grayscale lg:col-span-1"
          src="/1Password wordmark/Black, colour/1P-wordmark-536px-tall.png"
          alt="1Password"
          width={158}
          height={48}
        />
        <img
          className="align-center col-span-2 mt-4 max-h-12 w-full object-contain grayscale lg:col-span-1"
          src="/Slack-logo-black-RGB.svg"
          alt="Slack"
          width={158}
          height={48}
        />
      </div>
    </div>
  );
}
