import {
  ArrowPathIcon,
  FingerPrintIcon,
  ShieldCheckIcon,
  ChatBubbleLeftRightIcon,
} from '@heroicons/react/24/outline';
import useTitle from 'hooks/useTitle';
import HubspotContactForm from './HubspotContactForm';

const features = [
  {
    name: 'Compliance',
    description:
      'We take you and your staff across the SOC II compliance line.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Leasing',
    description:
      'Apple laptops on monthly subscription. Upgrade every 36 months. Trade in your old ones for cash.',
    icon: ArrowPathIcon,
  },
  {
    name: 'All-day Support',
    description:
      'That small tech problem that used to take up your whole day? Give it to us. Done.',
    icon: ChatBubbleLeftRightIcon,
  },
  {
    name: 'Advanced security',
    description:
      'Arcu egestas dolor vel iaculis in ipsum mauris. Tincidunt mattis aliquet hac quis. Id hac maecenas ac donec pharetra eget.',
    icon: FingerPrintIcon,
  },
];

export function HomeAlt() {
  useTitle('Equipped | Done');
  const openIntercom = () => {
    Intercom('show');
  };

  return (
    <div className="relative pt-14">
      <div className="py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="mt-2 text-5xl font-bold tracking-tight text-gray-900 sm:text-8xl">
              <span className="highlight-gradient">DONE!</span>
            </h2>
            <p className="mt-6 text-xl font-semibold leading-8 text-gray-600">
              Worried about compliance? The new computers your staff needs?
              Solving tech problems quickly?{' '}
              <span className="mt-6 block font-bold">
                Done. Done. And done.
              </span>
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                      <feature.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="mx-auto mt-28 max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Boost your productivity.
              <br />
              Start using Equipped today.
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
              We have a few more slots available for companies looking for any
              of: SOC II compliance, Mac leasing & support, or our{' '}
              <a className="link" href="/all-in-one">
                All-In-One
              </a>{' '}
              offer. Please fill this form (or{' '}
              <a className="link" onClick={openIntercom}>
                chat with us
              </a>
              ) and we'll get back to you promptly
            </p>
            <div className="relative mt-10 items-center justify-stretch gap-x-6 overflow-auto">
              <HubspotContactForm
                region="na1"
                portalId="23711572"
                formId="88537438-0306-40b0-8c1b-26402ff4433b"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
