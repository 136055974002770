import AssetTrackingCTA from 'components/CallsToAction';
import Markdown from 'components/Markdown';
import { faqs } from 'data/FAQs';
import useTitle from 'hooks/useTitle';

export default function FAQs() {
  useTitle('FAQs | Equipped');
  const openIntercom = () => {
    window.Intercom('show');
  };
  return (
    <main>
      <div className="scene">
        {/* FAQ section */}
        <div className="bg-white">
          <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20">
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <div>
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Frequently asked questions
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Can’t find the answer you’re looking for? Reach out to our{' '}
                  <a
                    onClick={openIntercom}
                    className="link font-medium text-indigo-500"
                  >
                    customer support
                  </a>{' '}
                  team or{' '}
                  <a
                    href="mailto:hello@tryequipped.com?subject=I have a question about Equipped"
                    target="blank"
                    className="font-medium text-indigo-500"
                  >
                    email us
                  </a>
                  .
                </p>
              </div>
              <div className="mt-12 lg:col-span-2 lg:mt-0">
                <dl className="space-y-12">
                  {faqs.map((faq) => (
                    <div key={faq.question}>
                      <dt className="text-lg font-medium leading-6 text-gray-900">
                        {faq.question}
                      </dt>
                      <dd className="mt-2 text-base text-gray-500">
                        <Markdown>{faq.answer}</Markdown>
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AssetTrackingCTA />
    </main>
  );
}
