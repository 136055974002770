import LeasingCTA from 'components/CallsToAction';
import Markdown from 'components/Markdown';
import AppleMulti from 'public/Multi-Product_MacBook_Pro_14-in_iPad_Pro_11-in_Apple_Pencil_2nd-gen_AirPods_Pro_2nd-Gen_iPhone_14_Pro_Max_iPhone_14_Pro_Apple_Watch_Series_8_Screen__USEN-1.webp';
import useTitle from 'hooks/useTitle';
import Link from 'components/Link';

import {
  ShieldCheckIcon,
  ChartPieIcon,
  CpuChipIcon,
  BanknotesIcon,
  AdjustmentsHorizontalIcon,
  ChatBubbleLeftRightIcon,
} from '@heroicons/react/20/solid';

const features = [
  {
    name: 'Lease or subscribe.',
    description: 'And stretch your cash.',
    icon: BanknotesIcon,
  },
  {
    name: 'Always on the latest Mac.',
    description: 'Productive and happy staff.',
    icon: CpuChipIcon,
  },
  {
    name: 'Compliance-ready Macs.',
    description: 'With device management.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Optimally configured.',
    description: 'Devices & software.',
    icon: AdjustmentsHorizontalIcon,
  },
  {
    name: 'All-day support.',
    description: 'Over Slack, email, or phone.',
    icon: ChatBubbleLeftRightIcon,
  },
];

export default function AppleDevices() {
  useTitle('Apple Devices | Equipped');
  const openIntercom = () => {
    window.Intercom('show');
  };
  return (
    <main>
      <div className="scene">
        <div className="overflow-hidden bg-white pb-16 pt-24 sm:pt-32">
          <div className="mx-auto max-w-7xl">
            <div className="grid grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-2 lg:items-start">
              <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                  <h2 className="text-base font-semibold leading-7 text-indigo-600">
                    Device-as-a-Service Subscriptions
                  </h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {
                      [
                        'Lease a Mac for your business from $23.90/mo.',
                        'Always on the latest Mac.',
                      ][Math.floor(2 * Math.random())]
                    }
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    Get your Apple laptops on an upgrade program with low
                    monthly payments, quick support, and smooth upgrades as
                    often as every other year.
                  </p>
                  <dl className="mt-8 max-w-xl space-y-2 text-base leading-7 text-gray-600 lg:max-w-none">
                    {features.map((feature) => (
                      <div key={feature.name} className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">
                          <feature.icon
                            className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                            aria-hidden="true"
                          />
                          {feature.name}
                        </dt>{' '}
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
                <div className="mx-auto mb-5 mt-8 grid max-w-lg grid-cols-1 gap-3 text-center lg:mx-0 xl:grid-cols-2">
                  <a
                    href="/users/sign_up"
                    className="btn-lg col-span-1 flex flex-row items-center justify-center rounded-lg bg-[#171717] px-5 py-3 text-lg font-semibold text-white"
                  >
                    Sign up today{' '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      width="24"
                      height="24"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                      ></path>
                    </svg>
                  </a>
                  <a
                    onClick={openIntercom}
                    className="col-span-1 rounded-lg border border-gray-900 px-5 py-3 text-lg font-semibold hover:bg-gray-100"
                  >
                    Talk to us
                  </a>
                </div>
              </div>
              <div className="sm:px-6 lg:px-0">
                <img
                  src={AppleMulti}
                  alt="Apple Devices"
                  width={1000}
                  height={800}
                  className="relative isolate overflow-hidden bg-white ring-1 ring-white/10 sm:mx-auto sm:rounded-3xl lg:mx-0 lg:max-w-none"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto">
          <div className="markdown mb-20">
            <Markdown>{`
            ## Cashflow optimization
            By leasing or subscribing to a Mac Upgrade Plan, you can save money in the long run by avoiding large
            upfront costs associated with purchasing new devices.
            Monthly or annual payments can make it more affordable to access the latest technology.
            Read more about payment plans in [the Leasing blog post](blog/Leasing).

            ## Always Up-to-Date Hardware
            Apple continually releases new and improved hardware. With an Upgrade plan, you can easily stay on top of the
            latest advancements without having to worry about your device becoming outdated. This ensures that you always have
            access to the most cutting-edge technology.

            For those roles that need the latest software and who run high-demand apps - like software development, data analysis, AI,
            video/audio media, and graphical design - sometime between year 2 and 3 is generally when the gap between what the
            hardware can do and what the new software is capable of starts to be felt.

            ## Reduced Maintenance and Support Costs
            As you are always using the latest hardware, the need for maintenance and support is often reduced. Additionally, our Apple Mac upgrade plans include AppleCare+ as part of the subscription, providing you with peace of mind and a hassle-free experience.

            Our [TechOps plans](/tech-ops) also include support and maintenance services. We partner with members of the Apple Consultants Network and certified specialists in the software we support and resell.

            ## Compliance-Ready Macs
            Get a MacBook Air or Pro with the latest version of macOS, pre-configured with your company's security and compliance settings. We can also install your company's software and configure your email, calendar, and chat apps.

            ## Enhanced Flexibility
            Leasing or subscribing to a Mac upgrade plan offers increased flexibility in terms of device choices and upgrade frequency. You can switch between different Mac models or upgrade more frequently to meet your changing needs and preferences.

            With our [Onboarding & Offboarding](/onboarding) services, we can also pick up used devices, wipe them, and redeploy them as loaners or new starter laptops. Our aim is to get the most out of every device throughout it's lifetime.

            `}</Markdown>
          </div>
        </div>
      </div>
      <LeasingCTA />
    </main>
  );
}
