import { useMemo } from 'react';
import Markdown from 'components/Markdown';
import { useQueryState } from 'hooks/useQueryState';
import { faqs } from 'data/FAQs';
import {
  ArrowPathIcon,
  ArrowUturnLeftIcon,
  CheckIcon,
  ChatBubbleLeftEllipsisIcon,
  CogIcon,
  DocumentChartBarIcon,
  HeartIcon,
  LockClosedIcon,
  ServerIcon,
  ShieldCheckIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';

import { BsLaptop } from 'react-icons/bs';
import { TbChartInfographic } from 'react-icons/tb';
import { AiOutlineSafety } from 'react-icons/ai';

import Header from 'layouts/Header';
import TopBar from 'layouts/TopBar';

import StorkClub from 'public/StorkClub.svg';
import Upgraded from 'public/Upgraded.svg';
import Halo from 'public/Halo.png';
import JDI from 'assets/images/JDI.svg';
import Pingboard from 'public/Pingboard.svg';
import Footer from 'layouts/Footer';
import useTitle from 'hooks/useTitle';
import Banner from 'layouts/Banner';
import Link from 'components/Link';

import { SwirlyDoodle } from 'components/Doodles';

export default function AllInOne() {
  useTitle('All-in-one | Equipped');
  return <Landing />;
}

function Landing() {
  const [tagLineInQuery, setTagLineInQuery] = useQueryState('tagline');
  const tagLine = useMemo(
    () => tagLineInQuery?.toString() || 'Run your business',
    [],
  );

  return (
    <>
      <TopBar className="hidden" />
      <Banner />
      <Header className="relative inset-x-0 top-0 z-10 mx-auto flex max-w-7xl items-center justify-between gap-4 p-6 lg:px-8" />
      <main>
        {/* Hero section */}
        <div className="relative mt-4">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="relative shadow-xl sm:overflow-hidden sm:rounded-2xl">
              <div className="top-30 absolute inset-0">
                <img
                  className="h-full w-full object-cover"
                  src="/photo-1521737852567-6949f3f9f2b5.avif"
                  alt="People working on laptops"
                />
                <div className="top-30 absolute inset-0 bg-gradient-to-r from-purple-800 to-indigo-700 mix-blend-multiply" />
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:px-8 lg:py-32">
                <h1 className="text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl">
                  <span
                    className="block text-white"
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={(e) => setTagLineInQuery(e.target.innerHTML)}
                    onInput={(e) => setTagLineInQuery(e.target.innerHTML)}
                  >
                    {tagLine}
                  </span>
                  <span className="block text-indigo-200">
                    .. and we handle the IT
                  </span>
                </h1>
                <p className="mx-auto mt-6 max-w-lg text-center text-xl text-indigo-200 sm:max-w-3xl">
                  We are <span className="font-bold italic">passionate</span>{' '}
                  about making laptops and software run smoothly. <br />
                  You shouldn't have to be. You do you, and leave the tech to
                  us!
                </p>
                <div className="mx-auto mt-10 max-w-sm sm:flex sm:max-w-none sm:justify-center">
                  <div className="space-y-4 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5 sm:space-y-0">
                    <a
                      href="/users/sign_up/?ref=all-in-one"
                      className="flex items-center justify-center rounded-md border border-transparent bg-indigo-50 px-4 py-3 text-base font-medium text-indigo-700 shadow-sm sm:px-8"
                    >
                      Get started
                    </a>
                    <Link
                      href="/docs"
                      className="flex items-center justify-center rounded-md border border-transparent bg-indigo-500 bg-opacity-70 px-4 py-3 text-base font-medium text-white shadow-sm sm:px-8"
                    >
                      Learn more
                    </Link>
                    <a
                      href="https://placehold.co/1920x1080.mp4?text=Place+Equipped+Demo+Here"
                      target="_blank"
                      className="flex hidden items-center justify-center rounded-md border border-transparent bg-indigo-500 bg-opacity-70 px-4 py-3 text-base font-medium text-white shadow-sm sm:px-8"
                    >
                      Watch demo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Logo Cloud */}
        <div className="bg-gray-100">
          <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
            <p className="text-center text-base font-semibold text-gray-500">
              Trusted by these{' '}
              <span className="font-extrabold">tech savvy</span> businesses
            </p>
            <div className="mt-6 grid grid-cols-2 place-items-center gap-8 md:grid-cols-6 lg:grid-cols-5">
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img
                  className="h-10 contrast-0 grayscale-0"
                  src={StorkClub}
                  alt="Stork Club"
                />
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img
                  className="h-10 contrast-0 grayscale-0"
                  src={Halo}
                  alt="Halo"
                />
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img
                  className="h-10 contrast-0 grayscale-0"
                  src={Upgraded}
                  alt="Upgraded"
                />
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 md:col-start-2 lg:col-span-1">
                <img
                  className="h-10 translate-y-5 scale-150 contrast-0 grayscale-0"
                  src={JDI}
                  alt="JDI"
                />
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 md:col-start-4 lg:col-span-1">
                <img
                  className="h-10 contrast-0 grayscale-0"
                  src={Pingboard}
                  alt="Pingboard"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Pricing */}
        <div id="pricing" className="bg-white">
          <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
            <div className="pb-16 xl:flex xl:items-center xl:justify-between">
              <div>
                <h1 className="text-4xl font-bold tracking-tight sm:text-5xl">
                  <span className="relative text-gray-900">
                    Everything
                    <SwirlyDoodle className="absolute left-0 top-3/4 hidden h-[.6em] w-full fill-purple-400 lg:inline" />
                  </span>
                  <span className="text-gray-900"> tech for</span>
                  <span className="text-indigo-600"> $250/user/month</span>
                </h1>
                <p className="mt-5 text-xl text-gray-500">
                  Includes Apple laptop, Google Workspace, Slack, 24x7 support,
                  and much more.
                </p>
              </div>
              <a
                href="/users/sign_up/?ref=all-in-one"
                className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-700 px-5 py-3 text-base font-medium text-white sm:mt-10 sm:w-auto xl:mt-0"
              >
                Get started today
              </a>
            </div>
            <div
              id="included"
              className="border-t border-gray-200 pt-16 xl:grid xl:grid-cols-3 xl:gap-x-8"
            >
              <div>
                <h2 className="text-lg font-semibold text-indigo-600">
                  Everything you need
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900">
                  All-in-one IT service
                </p>
                <p className="mt-4 text-lg text-gray-500">
                  We provide personal service directly to your staff to make
                  sure they have the devices and software they need to be
                  productive from day one.
                </p>
              </div>
              <div className="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:col-span-2 xl:mt-0">
                <ul role="list" className="divide-y divide-gray-200">
                  {priceFeatures.slice(0, 6).map((feature, featureIdx) =>
                    featureIdx === 0 ? (
                      <li key={feature} className="flex py-4 md:py-0 md:pb-4">
                        <CheckIcon
                          className="h-6 w-6 flex-shrink-0 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-base text-gray-500">
                          {feature}
                        </span>
                      </li>
                    ) : (
                      <li key={feature} className="flex py-4">
                        <CheckIcon
                          className="h-6 w-6 flex-shrink-0 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-base text-gray-500">
                          {feature}
                        </span>
                      </li>
                    ),
                  )}
                </ul>
                <ul
                  role="list"
                  className="divide-y divide-gray-200 border-t border-gray-200 md:border-t-0"
                >
                  {priceFeatures.slice(6).map((feature, featureIdx) =>
                    featureIdx === 0 ? (
                      <li
                        key={feature}
                        className="flex py-4 md:border-t-0 md:py-0 md:pb-4"
                      >
                        <CheckIcon
                          className="h-6 w-6 flex-shrink-0 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-base text-gray-500">
                          {feature}
                        </span>
                      </li>
                    ) : (
                      <li key={feature} className="flex py-4">
                        <CheckIcon
                          className="h-6 w-6 flex-shrink-0 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-base text-gray-500">
                          {feature}
                        </span>
                      </li>
                    ),
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Included section with grid */}
        <div className="relative hidden bg-white py-16 sm:py-24 lg:py-32">
          <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
            <h2 className="bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-lg font-semibold text-transparent">
              Work better
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              All the tech you need to run your business
            </p>
            <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
              A whole IT department's worth of services without the hassle of
              hiring and maintaining staff, maintaining inventory, and figuring
              out how to securly configure your software.
            </p>
            <div className="mt-12">
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                {included.map((item) => (
                  <div key={item.name} className="pt-6">
                    <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                      <div className="-mt-6">
                        <div>
                          <span className="inline-flex items-center justify-center rounded-md bg-gradient-to-r from-purple-600 to-indigo-600 p-3 shadow-lg">
                            <item.icon
                              className="h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                        <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                          {item.name}
                        </h3>
                        <p className="mt-5 text-base text-gray-500">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Alternating Feature Sections */}
        <div className="relative hidden overflow-hidden pb-32 pt-16">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
          />
          <div className="relative">
            <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
              <div className="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:px-0 lg:py-16">
                <div>
                  <div>
                    <span className="flex h-12 w-12 items-center justify-center rounded-md bg-gradient-to-r from-purple-600 to-indigo-600">
                      <BsLaptop
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                      Always run on fast machines{' '}
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Included in our monthly per user price is the latest
                      standard MacBook (currently the MacBook Pro 13" M2 8GB
                      with 256GB SSD). Every 36 months, we can ship a new one to
                      your staff, help with setting it up, and pick up the old
                      one, wipe it, and give it a new home. Higher spec machines
                      available.
                    </p>
                    <div className="mt-6">
                      <a
                        href="/users/sign_up/?ref=all-in-one"
                        className="inline-flex rounded-md border border-transparent bg-gradient-to-r from-purple-700 to-indigo-600 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:to-indigo-700"
                      >
                        Get started
                      </a>
                    </div>
                  </div>
                </div>
                <div className="mt-8 border-t border-gray-200 pt-6">
                  <blockquote>
                    <div>
                      <p className="text-base text-gray-500">
                        &ldquo;Wow! Just wow! sIn my last job I wasted so much
                        time waiting on my slow computer. IT spent hours trying
                        to help, but it barely made a difference.&rdquo;
                      </p>
                    </div>
                    <footer className="mt-3">
                      <div className="flex items-center space-x-3">
                        <div className="flex-shrink-0">
                          <img
                            className="h-6 w-6 rounded-full"
                            src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                            alt=""
                          />
                        </div>
                        <div className="text-base font-medium text-gray-700">
                          Marcia Hill, Digital Marketing Manager
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="https://images.unsplash.com/photo-1517336714731-489689fd1ca8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1026&q=80"
                    alt="Inbox user interface"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24">
            <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
              <div className="mx-auto max-w-xl px-4 sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:px-0 lg:py-32">
                <div>
                  <div>
                    <span className="flex h-12 w-12 items-center justify-center rounded-md bg-gradient-to-r from-purple-600 to-indigo-600">
                      <AiOutlineSafety
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                      Secure your data and software
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Your employees should be able to log in to the tools they
                      need easily and safely. We configure Google Workspace,
                      Slack, and 1Password with best practices. Use the same
                      multi-factor-secured login everywhere.
                    </p>
                    <div className="mt-6">
                      <a
                        href="/users/sign_up/?ref=all-in-one"
                        className="inline-flex rounded-md border border-transparent bg-gradient-to-r from-purple-700 to-indigo-600 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:to-indigo-700"
                      >
                        Get started
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
                <div className="-ml-48 pr-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={''}
                    alt="Customer profile user interface"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Gradient Feature Section */}
        <div className="hidden bg-gradient-to-r from-purple-800 to-indigo-700">
          <div className="mx-auto max-w-4xl px-4 py-16 sm:px-6 sm:pb-24 sm:pt-20 lg:max-w-7xl lg:px-8 lg:pt-24">
            <h2 className="text-3xl font-bold tracking-tight text-white">
              Designed for productivity
            </h2>
            <p className="mt-4 max-w-3xl text-lg text-purple-200">
              We operate best in class software and hardware so you and your
              staff can focus on building your company. IT problems are a
              distraction and a drain on your time and money.
            </p>
            <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name}>
                  <div>
                    <span className="flex h-12 w-12 items-center justify-center rounded-md bg-white bg-opacity-10">
                      <feature.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h3 className="text-lg font-medium text-white">
                      {feature.name}
                    </h3>
                    <p className="mt-2 text-base text-purple-200">
                      {feature.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Stats section */}
        <div className="relative bg-gray-900">
          <div className="absolute inset-x-0 bottom-0 h-80 xl:top-0 xl:h-full">
            <div className="h-full w-full xl:grid xl:grid-cols-2">
              <div className="h-full xl:relative xl:col-start-2">
                <img
                  className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                  src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
                  alt="People working on laptops"
                />
                <div
                  aria-hidden="true"
                  className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
                />
              </div>
            </div>
          </div>
          <div className="mx-auto max-w-4xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-flow-col-dense xl:grid-cols-2 xl:gap-x-8">
            <div className="relative pb-64 pt-12 sm:pb-64 sm:pt-24 xl:col-start-1 xl:pb-24">
              <h2 className="text-base font-semibold">
                <span className="bg-gradient-to-r from-purple-300 to-indigo-300 bg-clip-text text-transparent">
                  Stay Productive
                </span>
              </h2>
              <p className="mt-3 text-3xl font-bold tracking-tight text-white">
                Tech should never get in the way
              </p>
              <p className="mt-5 text-lg text-gray-300">
                In business, time is one of the most valuable currencies a
                company has. So, when precious minutes or hours are lost to
                unforeseen delays, perhaps at the hands of an old computer
                refusing to start up, that’s also money lost.
              </p>
              <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2">
                {metrics.map((item) => (
                  <p key={item.id}>
                    <span className="block text-2xl font-bold text-white">
                      {item.stat}
                    </span>
                    <span className="mt-1 block text-base text-gray-300">
                      <span className="font-medium text-white">
                        {item.emphasis}
                      </span>{' '}
                      {item.rest}
                    </span>
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* FAQ section */}
        <div className="bg-white">
          <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20">
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <div>
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Frequently asked questions
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Can’t find the answer you’re looking for? Reach out to our{' '}
                  <Link href="/contact" className="font-medium text-indigo-500">
                    customer support
                  </Link>{' '}
                  team.
                </p>
              </div>
              <div className="mt-12 lg:col-span-2 lg:mt-0">
                <dl className="space-y-12">
                  {faqs.map((faq) => (
                    <div key={faq.question}>
                      <dt className="text-lg font-medium leading-6 text-gray-900">
                        {faq.question}
                      </dt>
                      <dd className="mt-2 text-base text-gray-500">
                        <Markdown>{faq.answer}</Markdown>
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="bg-white">
          <div className="mx-auto max-w-4xl px-4 py-16 sm:px-6 sm:py-24 lg:flex lg:max-w-7xl lg:items-center lg:justify-between lg:px-8">
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">Ready to get started?</span>
              <span className="-mb-1 block bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text pb-1 text-transparent">
                Get in touch or create an account.
              </span>
            </h2>
            <div className="mt-6 space-y-4 sm:flex sm:space-x-5 sm:space-y-0">
              <Link
                href="/docs"
                className="flex items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-700 to-indigo-600 bg-origin-border px-4 py-3 text-base font-medium text-white shadow-sm hover:to-indigo-700"
              >
                Learn more
              </Link>
              <a
                className="flex items-center justify-center rounded-md border border-transparent bg-indigo-100 px-4 py-3 text-base font-medium text-indigo-800 shadow-sm"
                href="/users/sign_up/?ref=all-in-one"
              >
                Get started
              </a>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

const included = [
  {
    name: 'Laptop',
    description:
      'Latest Apple MacBook Air. Preconfigured. Secured. Managed with MDM. Delivered to remote staff',
    icon: BsLaptop,
  },
  {
    name: 'Device Preconfiguration',
    description:
      'New employee accounts created. Software installed. Secure configuration. Devices added to Apple Business Manager',
    icon: CogIcon,
  },
  {
    name: 'User Accounts',
    description:
      'New joiners will get user accounts created and equipment delivered before they start',
    icon: ArrowPathIcon,
  },
  {
    name: 'Advanced Security',
    description:
      'We run OS updates, security apps and more. Reast eas in the knowledge that your systems are up to date',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Inventory Tracking',
    description:
      'Know who has which device, track shipping, and when the next upgrade is due',
    icon: LockClosedIcon,
  },
  {
    name: 'Backups',
    description: 'We make sure your devices are always backed up and encrypted',
    icon: ServerIcon,
  },
];
const features = [
  {
    name: 'Fast Machines',
    description:
      'Always have the latest Macs. Every 36 months, we ship you a preconfigured new one and pick up the old one.',
    icon: BsLaptop,
  },
  {
    name: 'Support 24x7',
    description:
      "We're like the IT guy that never sleeps. We hire experts in Apple, Google, and Slack so you don't have to.",
    icon: UsersIcon,
  },
  {
    name: 'Safe and Secure',
    description:
      'We ensure you are protected from viruses, malware, and lost devices.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Predictable Cost',
    description:
      "Rest at ease knowing that you're only paying for active users. Know how much your IT will cost next month and for the whole year.",
    icon: TbChartInfographic,
  },
  {
    name: 'Cost Reporting',
    description:
      'We send you a monthly report of where your IT costs are going.',
    icon: DocumentChartBarIcon,
  },
  {
    name: 'Returns & Trade-Ins',
    description:
      'Get cash back for your unused devices. Stop paying when an employee leaves.',
    icon: ArrowUturnLeftIcon,
  },
  {
    name: 'Inventory Tracking',
    description: 'Keep track of your assets and who has what.',
    icon: ChatBubbleLeftEllipsisIcon,
  },
  {
    name: 'Trigger Shipments',
    description:
      'Send machines to new joiners. Pick up devices from folks moving on.',
    icon: HeartIcon,
  },
];
const priceFeatures = [
  'Apple MacBook Air (15" M2 8GB 512 SSD)',
  'Express delivery and pickup',
  'Device management and asset tracking',
  'Google Workspace Business Standard',
  'Slack Pro (with SSO)',
  '1Password for Teams',

  '24x7 IT support via Slack, email, & phone',
  'Anti-Virus/Malware/Spyware/Ransomware',
  'Insurance and replacement devices',
  'Trade-in of aged machines',
  'Data backups and recovery',
  "We'll get you across the SOC/ISO compliance finish line",
];
const metrics = [
  {
    id: 1,
    stat: '46%',
    emphasis: 'Workers',
    rest: 'admit outdated tech hinders their productivity.',
  },
  {
    id: 2,
    stat: '$3,818',
    emphasis: 'Dollars/year',
    rest: 'lost (based on average salary)',
  },
  {
    id: 3,
    stat: '46 mins/day',
    emphasis: 'Per employee.',
    rest: '24 days annually.',
  },
  { id: 4, stat: '8.8 days', emphasis: 'Booting up', rest: 'a slow computer!' },
];
