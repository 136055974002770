export interface WithACNsPillProps {
  className?: string;
  message?: string;
}
export const WithACNsPill = ({
  className = '',
  message = 'In partnership with members of the',
}: WithACNsPillProps) => {
  return (
    <div
      className={`items-top rounded-md bg-gray-900 p-1 pl-3 text-white ${className}`}
    >
      <span className="font-sans text-[10px] font-light text-gray-300 subpixel-antialiased">
        {message}
      </span>
      <br />
      <a
        href="https://consultants.apple.com/us"
        className="my-1 flex items-center text-xs font-semibold"
        target="_blank"
      >
        Apple Consultants Network{' '}
        <svg
          viewBox="0 0 20 20"
          className="ml-1.5 h-4 w-4 fill-slate-400"
          fillRule="evenodd"
          clipRule="evenodd"
        >
          <path d="M7 3.25H3.25v13.5h13.5V13h-1.5v2.25H4.75V4.75H7v-1.5Zm9.75 0H10v1.5h4.19l-5.72 5.72 1.06 1.06 5.72-5.72V10h1.5V3.25Z"></path>
        </svg>
      </a>
    </div>
  );
};
