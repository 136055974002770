import SessionContext from 'contexts/SessionContext';
import { useContext } from 'react';
import { getRootURL } from 'utils/urls';

export default function SignOutLink({ className }) {
  const { context } = useContext(SessionContext);

  function handleClick(event) {
    event.preventDefault();
    signOut(context);
  }

  function signOut(context) {
    const response = fetch('/users/sign_out.json', {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': context.csrfToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        window.location.href = getRootURL();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  return (
    <a
      href="/users/sign_out"
      onClick={handleClick}
      className={className}
      data-method="delete"
      rel="nofollow"
      data-csrf={context.csrfToken}
    >
      Sign Out
    </a>
  );
}
