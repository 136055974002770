import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import TopBar from './TopBar';
import Header from './Header';
import CookieConsent from 'components/CookieConsent';
import Alerts from 'components/Alerts';
import Banner from './Banner';
import SessionContext from 'contexts/SessionContext';
import { useContext } from 'react';

export function Layout() {
  const context = useContext(SessionContext);
  return (
    <>
      <TopBar className="hidden" />
      {context.context.user_signed_in ? null : <Banner />}
      <Header />
      <Alerts />
      <Outlet />
      <Footer />
      <CookieConsent />
    </>
  );
}

export function ShopLayout() {
  const context = useContext(SessionContext);
  return (
    <>
      <TopBar className="hidden" />
      {context.context.user_signed_in ? null : <Banner />}
      <Header />
      <Alerts />
      <Outlet />
      <CookieConsent />
    </>
  );
}
