import { useContext, useEffect, useState } from 'react';
import useCookie from 'hooks/useCookie';
import Link from './Link';
import { getCookie, removeCookie } from 'typescript-cookie';
import { usePostHog } from 'posthog-js/react';
import clsx from 'clsx';
import SessionContext from 'contexts/SessionContext';
import { ConsentValues } from 'types/main.d';
import { getRootDomain } from 'utils/urls';

export default function CookieConsent() {
  const windowDefined = typeof window !== 'undefined';
  const posthog = import.meta.env.POSTHOG_PUBLIC_KEY ? usePostHog() : null;

  const handleConsentChange = (newValue: ConsentValues | null) => {
    if (newValue === ConsentValues.all) {
      // Google analytics for conversion tracking
      if (windowDefined && import.meta.env.GOOGLE_ANALYTICS_KEY) {
        window[`ga-disable-${import.meta.env.GOOGLE_ANALYTICS_KEY}`] = false;
      }
    } else {
      if (windowDefined && import.meta.env.GOOGLE_ANALYTICS_KEY) {
        window[`ga-disable-${import.meta.env.GOOGLE_ANALYTICS_KEY}`] = true;
        removeCookie('_ga');
        removeCookie('_gat');
        removeCookie('_gid');
      }
    }

    if (
      newValue === null ||
      newValue === ConsentValues.all ||
      newValue === ConsentValues.essential
    ) {
      // Posthog analytics for debugging via screen recording
      posthog?.opt_in_capturing();
    }

    if (newValue === ConsentValues.none) {
      posthog?.opt_out_capturing();
    }

    // Zoominfo is controlled from the server side
    // TODO: Hubspot

    // if (import.meta.env.DEV) {
    //   console.log("GA opt out:",  window[`ga-disable-${import.meta.env.GOOGLE_ANALYTICS_KEY}`] || true)
    //   console.log("Posthog capture opt out:", posthog?.has_opted_out_capturing() || "posthog disabled")
    // }
  };

  useEffect(() => {
    const consent = getCookieConsentValue();
    handleConsentChange(consent);
  }, []);

  return <CookiePrompt onChange={handleConsentChange} />;
}

export function CookiePrompt({ onChange }) {
  const [cookie, updateCookie] = useCookie('consent', undefined);
  const context = useContext(SessionContext);
  const [consentValue, setConsentValue] = useState<ConsentValues | null>(
    context.consent || null,
  );

  useEffect(() => {
    setConsentValue(cookie || context.consent || null);
  }, [cookie, context.consent]);

  const changeSetting = (newValue: ConsentValues) => {
    updateCookie(newValue, { domain: getRootDomain(), path: '', expires: 365 });
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <section
      className={clsx(
        'bottom-6 left-4 mx-auto max-w-2xl rounded-2xl border border-gray-300 bg-white p-4 drop-shadow-md dark:border-gray-700 dark:bg-gray-900 md:items-center md:gap-x-4',
        consentValue === null ? 'fixed max-sm:mr-4 md:flex' : 'hidden',
      )}
    >
      <div className="flex items-center gap-x-4">
        <span className="inline-flex shrink-0 rounded-lg bg-blue-100/80 p-2 text-blue-500 dark:bg-gray-800">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.9803 8.5468C17.5123 8.69458 17.0197 8.7931 16.5271 8.7931C14.2118 8.76847 12.3399 6.89655 12.3153 4.58128C12.3153 4.13793 12.3892 3.69458 12.537 3.27586C11.9951 2.68473 11.6995 1.92118 11.6995 1.13301C11.6995 0.812808 11.7488 0.492611 11.8473 0.172414C11.2315 0.0738918 10.6158 0 10 0C4.48276 0 0 4.48276 0 10C0 15.5172 4.48276 20 10 20C15.5172 20 20 15.5172 20 10C20 9.77833 20 9.55665 19.9754 9.33498C19.2611 9.26108 18.5468 8.99015 17.9803 8.5468ZM4.58128 7.31527C6.30542 7.31527 6.30542 10.0246 4.58128 10.0246C2.85714 10.0246 2.61084 7.31527 4.58128 7.31527ZM6.05912 15.7635C4.08867 15.7635 4.08867 12.8079 6.05912 12.8079C8.02956 12.8079 8.02956 15.7635 6.05912 15.7635ZM9.01478 1.33005C10.7389 1.33005 10.7389 4.28571 9.01478 4.28571C7.29064 4.28571 7.04434 1.33005 9.01478 1.33005ZM10.2463 8.84237C11.7241 8.84237 11.7241 10.8128 10.2463 10.8128C8.76848 10.8128 9.01478 8.84237 10.2463 8.84237ZM11.9704 16.9458C10.4926 16.9458 10.4926 14.9754 11.9704 14.9754C13.4483 14.9754 13.202 16.9458 11.9704 16.9458ZM16.6503 13.1034C15.4187 13.1034 15.4187 11.133 16.6503 11.133C17.8818 11.133 17.8818 13.1034 16.6503 13.1034Z"
              fill="currentColor"
            />
          </svg>
        </span>

        <p className="text-sm text-gray-600 dark:text-gray-300">
          We use cookies to improve our site and user experience. We don't sell
          your data.{' '}
          <Link href="/privacy" className="text-blue-500 hover:underline">
            Privacy policy
          </Link>
          .{' '}
        </p>
      </div>

      <div className="mt-6 flex shrink-0 gap-x-4 md:items-center lg:mt-0">
        {/* <button onClick={() => {changeSetting(ConsentValues.none)}} className="w-1/2 text-xs text-gray-800 underline transition-colors duration-300 md:w-auto dark:text-white dark:hover:text-gray-400 hover:text-gray-600 focus:outline-none">
              none
          </button> */}
        <button
          onClick={() => {
            changeSetting(ConsentValues.essential);
          }}
          className="w-1/3 text-xs text-gray-800 underline transition-colors duration-300 hover:text-gray-600 focus:outline-none dark:text-white dark:hover:text-gray-400 md:w-auto"
        >
          only essential
        </button>

        <button
          onClick={() => {
            changeSetting(ConsentValues.all);
          }}
          className="w-1/2 rounded-lg bg-gray-800 px-4 py-2.5 text-xs font-medium text-white transition-colors duration-300 hover:bg-gray-700 focus:outline-none md:w-auto"
        >
          Accept All Cookies
        </button>
        {/* keep ~w-1/6 open for Intercom on mobile */}
      </div>
    </section>
  );
}

export function resetCookiePromp() {
  removeCookie('consent', { domain: getRootDomain(), path: '' });
}

export function getCookieConsentValue(): ConsentValues | null {
  const consentValue = getCookie('consent');
  if (consentValue) {
    return consentValue as ConsentValues;
  }
  return null;
}
