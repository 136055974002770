import useTitle from 'hooks/useTitle';
import { AllInOneCTA } from 'components/CallsToAction';
import Markdown from 'components/Markdown';

export default function TechOps() {
  useTitle('TechOps | Equipped');
  return (
    <main>
      <div className="scene">
        <div className="mx-auto">
          <div className="flex flex-col items-center justify-center py-20 text-center lg:mt-14">
            {' '}
            <h2 className="mb-4 text-2xl font-semibold leading-7 text-indigo-700">
              Spending time on
            </h2>
            <h1 className="mb-5 text-4xl md:text-5xl lg:text-6xl">
              Setting Systems Up?
            </h1>
            <p className="mb-10 text-lg text-gray-600">
              Apple Mac upgrade plans can be a great option for those looking to
              stay up-to-date with the latest technology.
            </p>
          </div>
          <div className="markdown mb-20">
            <Markdown>{`
            ## Equipped Tech Ops & Support
            The ultimate solution for businesses seeking streamlined, efficient, and secure technology operations without the need for in-house IT personnel. In today's highly competitive market, organizations must focus on their core competencies while ensuring seamless integration of technology and systems. Equipped Tech Ops & Support offers expert services to configure and set up your systems, allowing you to focus on what truly matters – growing your business. With our team of professionals by your side, you can enjoy the benefits of a comprehensive technology support system without the added burden of hiring and managing IT staff.

            ## On-call experts
            Our on-call team is always at the ready to answer your questions or help resolve any issues that you or your staff may encounter. This 24/7 support ensures that your business operations run smoothly, with minimal downtime or disruptions. Our experienced professionals possess extensive knowledge of various systems and platforms, providing your organization with an unparalleled level of support. With Equipped Tech Ops & Support, you can trust that any technical issue will be handled efficiently and effectively, allowing you and your team to maintain focus on your core business objectives.

            ## Single Sign-On
            > Log on to your Mac with your Google login! No need to set up a new account. No need to remember a new password.
            
            An example of the features we enable is single sign-on (SSO) technology, allowing users to log in to their Mac with their Google credentials. This eliminates the need to create and remember new account information, significantly reducing the risk of compromised credentials. By simplifying the login process, our service not only enhances user experience but also bolsters the security of your organization's digital assets. The convenience and peace of mind provided by SSO technology is just one example of how Equipped Tech Ops & Support streamlines your business's technology operations.

            ## Apple laptop included
            Our all-inclusive package includes the delivery of a cutting-edge Apple MacBook Pro or Air, tailored to the needs and preferences of your new team members. We understand the importance of having the right tools to work effectively and efficiently, which is why our service ensures your remote employees have the best technology at their fingertips. As part of our commitment to provide a seamless onboarding experience, we pre-install and configure Google Workspace, Slack, and 1Password on the laptop to ensure that your new hires can dive right into their work with minimal setup required.

            ## Must have
            For smaller companies that cannot afford to have experts on staff, Equipped Tech Ops & Support is a game-changer. By outsourcing your technology operations and support needs to our team, you can enjoy the benefits of expert guidance without the financial strain of hiring a dedicated IT professional. This cost-effective solution enables smaller organizations to level the playing field against larger competitors, ensuring that your business has access to the same level of technical expertise as industry giants. With Equipped Tech Ops & Support, you can rest easy knowing that your technology systems are in capable hands, leaving you free to focus on your company's growth and success.
            `}</Markdown>
          </div>
        </div>
      </div>
      <AllInOneCTA />
    </main>
  );
}
