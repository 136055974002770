import Leasing from './blog-leasing.md';
import Onboarding from './blog-onboarding.md';

export const authors = {
  ziad: {
    name: 'Ziad Sawalha',
    avatar: '/avatar-ziad.png',
    link: 'https://twitter.com/ziadsawalha',
  },
  william: {
    name: 'William Franzer',
    avatar: '/avatar-william.png',
    link: 'http://upgraded.ninja',
  },
  jason: {
    name: 'Jason Kitchener',
    avatar: '/avatar-jason.png',
    link: 'https://www.linkedin.com/in/jasonkitchener/',
  },
};

export const posts = [
  {
    title: 'Leasing',
    author: 'william',
    date: 'May 10, 2023',
    description:
      "When and why should you lease or buy? We'll help you make the right decision for your business.",
    content: Leasing,
    id: 'Leasing',
    relatedArticles: [
      {
        title: 'Always own the latest Mac.',
        url: '/apple-devices',
      },
    ],
  },
  {
    title: 'Onboarding',
    author: 'jason',
    date: 'May 23, 2023',
    description:
      'Why should startups outsource their technology onboarding/offboarding?',
    content: Onboarding,
    id: 'Onboarding',
    relatedArticles: [
      {
        title: 'Product | Onboarding & Offboarding.',
        url: '/onboarding',
      },
    ],
  },
];

const otherThings = [
  {
    title: 'Announcing Equipped',
    date: 'September 1, 2022',
    description:
      "We've decided to focus our efforts on building Equipped. We're excited to share more about our new product soon.",
    id: 'Launch',
  },
  {
    title: ' Apple Authorized!',
    date: 'November 7, 2022',
    description:
      "We've been authorized by Apple to reseller Apple devices to businesses in North America.",
    id: 'Apple',
  },
  {
    title: '1Password Partner',
    date: 'December 20, 2022',
    description:
      "We've been authorized by 1Password to be part of the 1Password Partner Program. We can now resell and bundle 1Password in our offers.",
    id: '1Password',
  },
  {
    title: 'Google Cloud Partner Advantage',
    date: 'December 21, 2022',
    description:
      "We've been approved by Google to be part of the Google Cloud Partner Advantage. We can now resell Google Workspace to our customers.",
    id: 'Google',
  },
  {
    title: 'Vanta MSP Program membership',
    date: 'March 9, 2023',
    description:
      "We've been approved by Vanta to be part of their MSP program. We can now resell and bundle Vanta on our products.",
    id: 'Vanta',
  },
  {
    title: 'Upgraded in the US!',
    date: 'April 16, 2023',
    description:
      'Our sister company, Upgraded, has now launched in the US. Get the latest tech for your business, with no upfront costs.',
    id: 'Upgraded',
  },
  {
    title: 'Done, done, and done!',
    date: 'April 28, 2023',
    description:
      "We're announcing the Upgraded and Equipped are members of The Done Group of companies.",
    id: 'TheDoneCompany',
  },
  {
    title: 'What is MDM or ABM? And why do I need it?',
    date: 'April 24, 2023',
    description:
      'Learn all about Device Management and how it can protect your assets and data.',
    id: 'MDM',
  },
];
