import React, { useContext, useRef, useState } from 'react';
import useTitle from 'hooks/useTitle';
import Link from 'components/Link';
import clsx from 'clsx';
import SessionContext from 'contexts/SessionContext';
import { flushSync } from 'react-dom';
import InvisibleCaptcha from 'components/InvisibleCaptcha';

export default function Subscribe() {
  useTitle('Subscribe | Equipped');
  const context = useContext(SessionContext);
  const [honeypotValue, setHoneypotValue] = useState('');
  const [data, setData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    company: '',
    phoneNumber: '',
    acceptTerms: false,
    source: 'subscribe',
    message: '',
  });

  function updateValues(changedValues) {
    setData((prevState) => {
      return { ...prevState, ...changedValues };
    });
  }

  const submitButton = useRef<HTMLButtonElement>(null);

  // Dev only
  function fillFormAndSubmit(
    e: React.MouseEvent<HTMLAnchorElement>,
    fillHoneypot: boolean,
    disposableEmail: boolean = false,
  ) {
    e.preventDefault();
    flushSync(() => {
      setData({
        email: disposableEmail
          ? 'email@example.com'
          : 'wile.e.coyote@acme.corp',
        firstName: 'Wile E',
        lastName: 'Coyote',
        company: 'ACME, Inc.',
        phoneNumber: '+1 (555) 123-1234',
        acceptTerms: true,
        source: 'subscribe',
        message: 'Tell me more!',
      });
      if (fillHoneypot) {
        setHoneypotValue('I am a spammer and I love spamming you.');
      }
    });
    submitButton.current?.click();
  }

  return (
    <main>
      <div className="scene">
        <div className="mx-auto">
          <div className="flex flex-col items-center justify-center py-20 text-center lg:mt-14">
            {' '}
            <h2 className="mb-4 text-2xl font-semibold leading-7 text-indigo-700">
              Subscribe
            </h2>
            <h1 className="mb-5 text-4xl md:text-5xl lg:text-6xl">
              And we'll keep you posted
            </h1>
            <p className="mb-10 text-lg text-gray-600">
              Apple Mac upgrade plans can be a great option for those looking to
              stay up-to-date with the latest technology.
            </p>
          </div>

          <form
            id="main"
            action="/email/signup"
            method="post"
            className="mx-auto mb-12 mt-16 max-w-xl sm:mt-12"
            data-turbo="false"
          >
            {import.meta.env.DEV ? (
              <div className="rounded-md bg-yellow-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg
                      className="h-5 w-5 text-yellow-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">
                      Dev Helper
                    </h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      <p>
                        <a
                          className="font-medium text-yellow-600 hover:text-yellow-500"
                          onClick={(e) => fillFormAndSubmit(e, false)}
                          href="#"
                        >
                          Fill & submit
                        </a>
                        .
                      </p>
                      <p>
                        <a
                          className="font-medium text-yellow-600 hover:text-yellow-500"
                          onClick={(e) => fillFormAndSubmit(e, true)}
                          href="#"
                        >
                          Fill & submit
                        </a>{' '}
                        with honeypot (like a bot would).
                      </p>
                      <p>
                        <a
                          className="font-medium text-yellow-600 hover:text-yellow-500"
                          onClick={(e) => fillFormAndSubmit(e, false, true)}
                          href="#"
                        >
                          Fill & submit
                        </a>{' '}
                        with disposable email.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <input
              value="subscribe"
              autoComplete="off"
              type="hidden"
              name="email_signup[source]"
              id="email_signup_source"
            ></input>
            <input
              value={context.csrfToken}
              autoComplete="off"
              type="hidden"
              name="authenticity_token"
              readOnly={true}
            ></input>
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  First name
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="email_signup[firstName]"
                    id="first-name"
                    value={data.firstName}
                    onChange={(e) =>
                      updateValues({ firstName: e.target.value })
                    }
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Last name
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="email_signup[lastName]"
                    id="last-name"
                    autoComplete="family-name"
                    value={data.lastName}
                    onChange={(e) => updateValues({ lastName: e.target.value })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="company"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Company
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="email_signup[company]"
                    id="company"
                    value={data.company}
                    onChange={(e) => updateValues({ company: e.target.value })}
                    autoComplete="organization"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2.5">
                  <input
                    type="email"
                    name="email_signup[email]"
                    id="email"
                    autoComplete="email"
                    value={data.email}
                    onChange={(e) => updateValues({ email: e.target.value })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Phone number
                </label>
                <div className="relative mt-2.5">
                  <div className="absolute inset-y-0 left-0 flex items-center">
                    <label htmlFor="country" className="sr-only">
                      Country
                    </label>
                    <select
                      id="country"
                      name="email_signup[country]"
                      className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    >
                      <option>US</option>
                    </select>
                    <svg
                      className="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="tel"
                    name="email_signup[phoneNumber]"
                    id="phone-number"
                    value={data.phoneNumber}
                    onChange={(e) =>
                      updateValues({ phoneNumber: e.target.value })
                    }
                    autoComplete="tel"
                    className="block w-full rounded-md border-0 px-3.5 py-2 pl-20 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Message
                </label>
                <div className="mt-2.5">
                  <textarea
                    name="email_signup[message]"
                    id="message"
                    value={data.message}
                    onChange={(e) => updateValues({ message: e.target.value })}
                    rows={4}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  ></textarea>
                </div>
              </div>
              <div className="flex gap-x-4 sm:col-span-2">
                <div className="flex h-6 items-center">
                  <button
                    type="button"
                    onClick={() =>
                      updateValues({ acceptTerms: !data.acceptTerms })
                    }
                    className={clsx(
                      data.acceptTerms ? 'bg-indigo-600' : 'bg-gray-200',
                      'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
                    )}
                    role="switch"
                    aria-checked="false"
                    aria-labelledby="switch-1-label"
                  >
                    <span className="sr-only">Agree to policies</span>
                    <span
                      aria-hidden="true"
                      className={clsx(
                        data.acceptTerms ? 'translate-x-3.5' : 'translate-x-0',
                        'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out',
                      )}
                    ></span>
                  </button>
                </div>
                <label
                  className="text-sm leading-6 text-gray-600"
                  id="switch-1-label"
                >
                  By clicking "Let's talk", you agree to our{' '}
                  <Link
                    href="/privacy"
                    className="font-semibold text-indigo-600"
                  >
                    privacy&nbsp;policy
                  </Link>
                  .
                </label>
              </div>
            </div>
            <InvisibleCaptcha
              honeypotValue={honeypotValue}
              setHoneypotValue={setHoneypotValue}
            />
            <div className="mt-10">
              <button
                id="submit"
                ref={submitButton}
                type="submit"
                className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Let's talk
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}
