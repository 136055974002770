import Link from 'components/Link';
import { BsFacebook, BsLinkedin } from 'react-icons/bs';

const footerNavigation = {
  product: [
    { name: 'TechOps', href: '/tech-ops/' },
    { name: 'Compliance', href: '/compliance/' },
    { name: 'All-in-one', href: '/all-in-one/' },
    { name: 'Pricing', href: '/pricing/' },
  ],
  support: [
    { name: 'Contact', href: '/contact/' },
    { name: 'Documentation', href: '/docs/' },
    { name: 'FAQs', href: '/faq/' },
    //{ name: "🚧 Guides", href: "#" },
    //{ name: "🚧 API Reference", href: "#" },
  ],
  company: [
    { name: 'Home', href: '/' },
    { name: 'About', href: '/about/' },
    { name: 'Blog', href: '/blog/' },
    { name: 'Jobs', href: 'https://www.linkedin.com/jobs/view/3553528070/' },
    //{ name: "Press", href: "#" },
    { name: 'Partners', href: '/partners/' },
  ],
  legal: [
    { name: 'Privacy', href: '/privacy/' },
    { name: 'Terms', href: '/terms/' },
    { name: 'Security', href: '/security/' },
    //{ name: "🚧 Leasing contract", href: "#" },
  ],
};

export default function Footer() {
  function getStatusURL() {
    if (import.meta.env.ROOT_DOMAIN) {
      return 'https://status.' + import.meta.env.ROOT_DOMAIN;
    } else {
      console.debug('ROOT_DOMAIN not defined!');
      return (
        'https://status.' +
        window.location.hostname.split('.').slice(-2).join('.')
      );
    }
  }

  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <footer
        aria-labelledby="footer-heading"
        className="sm:mt-18 relative border-t border-gray-900/10 py-6 sm:py-12"
      >
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="mt-8 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="sm:grid-cols-2 md:grid md:gap-8">
              <div>
                <h3 className="text-sm font-bold leading-6 text-gray-900">
                  Product
                </h3>
                <ul role="list" className="mt-4 space-y-2">
                  {footerNavigation.product.map((item) => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="text-sm font-medium leading-6 text-gray-600 hover:text-gray-900"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-bold leading-6 text-gray-900">
                  Support
                </h3>
                <ul role="list" className="mt-4 space-y-2">
                  {footerNavigation.support.map((item) => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="text-sm font-medium leading-6 text-gray-600 hover:text-gray-900"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                  <li key="status" className="-ml-4">
                    <Link
                      className="text-sm text-gray-600"
                      rel="nofollow"
                      href={getStatusURL()}
                    >
                      <span className="flex flex-row items-center justify-start font-medium">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16"
                          width="8"
                          height="8"
                          fill="currentColor"
                          className="mr-2 text-green-500"
                        >
                          <circle cx="8" cy="8" r="8"></circle>
                        </svg>
                        Service status
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-bold leading-6 text-gray-900">
                  Company
                </h3>
                <ul role="list" className="mt-4 space-y-2">
                  {footerNavigation.company.map((item) => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="text-sm font-medium leading-6 text-gray-600 hover:text-gray-900"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-bold leading-6 text-gray-900">
                  Legal
                </h3>
                <ul role="list" className="mt-4 space-y-2">
                  {footerNavigation.legal.map((item) => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="text-sm font-medium leading-6 text-gray-600 hover:text-gray-900"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 items-center justify-center py-10 lg:grid-cols-3">
          <div className="col-span-3 mb-10 lg:col-span-2 lg:mb-0">
            <div className="mb-3 flex">
              <Link href="/">
                <img
                  className="block h-8"
                  src="/Equipped Primary Black.svg"
                  width="auto"
                  height="100%"
                  alt="Equipped"
                />
              </Link>
            </div>
            <p className="text-sm text-gray-600">
              &copy; 2023 Equipped Technologies, Inc. All rights reserved.
            </p>
          </div>
          <div className="col-span-3 flex flex-col text-left lg:col-span-1 lg:text-right">
            <div className="mb-3 flex flex-row justify-start space-x-3 lg:justify-end">
              <a
                href="https://twitter.com/tryequipped"
                rel="noopener"
                target="_blank"
                className="text-gray-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  className="bi bi-twitter"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path>
                </svg>
              </a>
              <a
                href="https://www.facebook.com/tryequipped/"
                rel="noopener"
                target="_blank"
                className="text-gray-600"
              >
                <BsFacebook className="h-[18px] w-[18px]" />
              </a>
              <a
                href="https://www.linkedin.com/in/equipped/"
                rel="noopener"
                target="_blank"
                className="text-gray-600"
              >
                <BsLinkedin className="h-[18px] w-[18px]" />
              </a>
              <a
                href="https://instagram.com/tryequipped/"
                rel="noopener"
                target="_blank"
                className="text-gray-600"
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="2 2 20 20"
                  height="18"
                  width="18"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path
                      fillRule="nonzero"
                      d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"
                    ></path>
                  </g>
                </svg>
              </a>
              <a
                href="https://github.com/tryequipped"
                rel="noopener"
                target="_blank"
                className="text-gray-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  className="bi bi-github"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"></path>
                </svg>
              </a>
            </div>
            <a
              href="mailto:support@tryequipped.com"
              className="flex items-center justify-end space-x-2 text-sm text-gray-600 xl:justify-end"
            >
              <span className="font-medium">Need help?</span>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                  ></path>
                </svg>
              </span>
              <span>support@tryequipped.com</span>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
