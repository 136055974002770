import React, { Component, ErrorInfo, ReactNode } from 'react';
import ErrorPage from './ErrorPage';
import * as Sentry from '@sentry/react';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo); // eslint-disable-line no-console
  }

  public render() {
    if (this.state.hasError) {
      return <SentryBoundery />;
    }

    return this.props.children;
  }
}

class SentryBoundery extends React.Component {
  render() {
    return (
      <Sentry.ErrorBoundary
        fallback={({ error, componentStack, resetError }) => <ErrorPage />}
      ></Sentry.ErrorBoundary>
    );
  }
}

export default ErrorBoundary;
