export function getURLWithSubdomain(subdomainToAdd: string) {
  if (typeof window === 'undefined') {
    // SSR
    if (process.env.NODE_ENV === 'development') {
      return `http://${subdomainToAdd}.${import.meta.env.ROOT_DOMAIN}:${
        import.meta.env.VITE_PORT
      }`;
    } else {
      return `https://${subdomainToAdd}.${import.meta.env.ROOT_DOMAIN}`;
    }
  }
  const currentURL = new URL(window.location.href);
  const domainParts = currentURL.hostname.split('.');

  // If the current hostname has a subdomain (e.g., www.example.com)
  if (domainParts.length > 2) {
    domainParts[0] = subdomainToAdd; // Replace the existing subdomain
  } else {
    // If the current hostname does not have a subdomain (e.g., example.com)
    domainParts.unshift(subdomainToAdd); // Add the new subdomain
  }

  currentURL.hostname = domainParts.join('.');
  return currentURL.toString();
}

export function getRootURL() {
  const currentURL = new URL(window.location.href);
  const domainParts = currentURL.hostname.split('.');

  // If the current hostname has a subdomain (e.g., www.example.com)
  if (domainParts.length > 2) {
    domainParts.shift(); // Replace the existing subdomain
  }

  currentURL.hostname = domainParts.join('.');
  return currentURL.toString();
}

export function getRootDomain() {
  if (typeof window === 'undefined') {
    return '.' + import.meta.env.ROOT_DOMAIN;
  } else {
    return '.' + window.location.hostname.split('.').slice(-2).join('.');
  }
}

export default getRootDomain;
