import { XMarkIcon } from '@heroicons/react/20/solid';
import Link from 'components/Link';

export default function Banner() {
  return (
    <div className="flex items-center gap-x-6 bg-[#171717] px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <p className="text-sm leading-6 text-white">
        <a href="/users/sign_up/?ref=banner">
          <strong className="font-semibold">We're building Equipped</strong>
          <svg
            viewBox="0 0 2 2"
            className="mx-2 inline h-0.5 w-0.5 fill-current"
            aria-hidden="true"
          >
            <circle cx={1} cy={1} r={1} />
          </svg>
          Join as an early adopter. Benefit from our service today and steer our
          roadmap to meet your team's needs&nbsp;
          <span aria-hidden="true">&rarr;</span>
        </a>
      </p>
      <div className="flex flex-1 justify-end">
        <button
          type="button"
          className="-m-3 hidden p-3 focus-visible:outline-offset-[-4px]"
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}
