import { dataImagePlaceholder } from './ImagePlaceholder';
import MacBookProM2 from 'public/MacBook_Pro_M2_Chip_Hero_Horizontal_Screen__USEN.webp';
import SkyDiving from 'public/skydive.jpeg';
import Link from './Link';

export default function LeasingCTA() {
  return (
    <div className="">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="overflow-hidden rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-300 lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="px-6 pb-12 pt-10 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:px-20 xl:py-20">
            <div className="lg:mt-8 lg:self-center">
              <h2 className="mb-5 text-4xl font-extrabold text-white sm:text-4xl">
                <span className="block">
                  Order a{' '}
                  <span className="highlight-gradient-dark-bg">
                    MacBook Pro
                  </span>{' '}
                  today.
                </span>
              </h2>
              <p className="mb-5 mt-2 text-lg leading-6 text-gray-300">
                Upgrade Plans available · Trade-in older devices
              </p>
              <a
                href="/users/sign_up/?ref=cta"
                className="inline-flex items-center rounded-md border border-gray-100 px-5 py-3 text-base font-medium text-gray-100 transition-colors hover:bg-gray-800 hover:no-underline"
              >
                Try us as your preferred Apple reseller{' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="aspect-h-3 aspect-w-5 -mt-6 md:aspect-h-1 md:aspect-w-2">
            <img
              className="translate-x-6 translate-y-6 rounded-md object-cover object-left-top lg:translate-y-24"
              src={MacBookProM2}
              alt="MacBook Pro M2"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export function AssetTrackingCTA() {
  return (
    <div className="">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="overflow-hidden rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-300 lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="px-6 pb-12 pt-10 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:px-20 xl:py-20">
            <div className="lg:mt-8 lg:self-center">
              <h2 className="mb-5 text-4xl font-extrabold text-white sm:text-4xl">
                <span className="block">
                  <span className="highlight-gradient-dark-bg">
                    Asset tracking
                  </span>{' '}
                  for all your tech
                </span>
              </h2>
              <p className="mb-5 mt-2 text-lg leading-6 text-gray-300">
                Free plan available · No credit card required
              </p>
              <a
                href="/users/sign_up/?ref=cta"
                className="inline-flex items-center rounded-md border border-gray-100 px-5 py-3 text-base font-medium text-gray-100 transition-colors hover:bg-gray-800 hover:no-underline"
              >
                Start for free{' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="aspect-h-3 aspect-w-5 -mt-6 md:aspect-h-1 md:aspect-w-2">
            <img
              className="translate-x-6 translate-y-6 rounded-md object-cover object-left-top lg:translate-y-24"
              src={dataImagePlaceholder({ bgColor: '#1f2937' })}
              alt="Asset Tracking"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export function AllInOneCTA() {
  return (
    <div className="">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="overflow-hidden rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-300 lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="px-6 pb-12 pt-10 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:px-20 xl:py-20">
            <div className="lg:mt-8 lg:self-center">
              <h2 className="mb-5 text-4xl font-extrabold text-white sm:text-4xl">
                <span className="block">
                  <span className="highlight-gradient-dark-bg">
                    Be free of IT!
                  </span>
                  <br />
                  The All-In-One package.
                </span>
              </h2>
              <p className="mb-5 mt-2 text-lg leading-6 text-gray-300">
                Transparent pricing · No lock in · Mac included!
              </p>
              <Link
                href="/all-in-one/?ref=cta"
                className="inline-flex items-center rounded-md border border-gray-100 px-5 py-3 text-base font-medium text-gray-100 transition-colors hover:bg-gray-800 hover:no-underline"
              >
                Everything for $250/user/month{' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                  ></path>
                </svg>
              </Link>
              <div className="mt-2 text-sm italic text-gray-400">
                Set your tech budget to max. $3,000/employee/year
              </div>
            </div>
          </div>
          <div className="aspect-h-3 aspect-w-5 -mt-6 md:aspect-h-1 md:aspect-w-2">
            <img
              className="translate-x-16 translate-y-12 rounded-md object-cover object-left-top opacity-90 grayscale lg:translate-y-24"
              src={SkyDiving}
              alt="All-In-One"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export function JoinAsACNCTA() {
  return (
    <div className="">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="overflow-hidden rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-300 lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="px-6 pb-12 pt-10 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:px-20 xl:py-20">
            <div className="lg:mt-8 lg:self-center">
              <h2 className="mb-5 text-4xl font-extrabold text-white sm:text-4xl">
                <span className="block">
                  <span className="highlight-gradient-dark-bg">
                    Simpler purchasing
                  </span>{' '}
                  for you and your customers
                </span>
              </h2>
              <p className="mb-5 mt-2 text-lg leading-6 text-gray-300">
                Apple Devices · Financing · Slack channel
              </p>
              <a
                href="/users/sign_up?ref=CTA&success_url=/accounts/new_acn"
                className="inline-flex items-center rounded-md border border-gray-100 px-5 py-3 text-base font-medium text-gray-100 transition-colors hover:bg-gray-800 hover:no-underline"
              >
                Try us out. It's free{' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="aspect-h-3 aspect-w-5 -mt-6 md:aspect-h-1 md:aspect-w-2">
            <img
              className="translate-x-6 translate-y-6 rounded-md object-cover object-left-top lg:translate-y-24"
              src={dataImagePlaceholder({ bgColor: '#1f2937' })}
              alt="Apple Authorized Reseller for Consultants"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}
