import useTitle from 'hooks/useTitle';
import { AssetTrackingCTA } from 'components/CallsToAction';
import Markdown from 'components/Markdown';

export default function Onboarding() {
  useTitle('Onboarding | Equipped');
  return (
    <main>
      <div className="scene">
        <div className="mx-auto">
          <div className="flex flex-col items-center justify-center py-20 text-center lg:mt-14">
            <h2 className="mb-4 text-2xl font-semibold leading-7 text-indigo-700">
              Worried about
            </h2>
            <h1 className="mb-5 text-4xl md:text-5xl lg:text-6xl">
              Onboarding & Offboarding Staff?
            </h1>
            <p className="mb-10 text-lg text-gray-600">
              We got it. We'll deliver an Apple Mac to them before their start
              date. We'll pick up gear from leavers.
            </p>
          </div>
          <div className="markdown mb-20">
            <Markdown>{`
            ## Easy Onboarding
            Introducing Equipped Onboarding, the ultimate solution for seamless onboarding and offboarding of remote team members. In today's fast-paced business environment, ensuring a smooth transition for new employees and outgoing staff is paramount to maintaining productivity and company culture. Equipped Speed Boarding addresses this critical need by providing a comprehensive package that takes care of delivering the necessary hardware and setting up essential software tools for remote workers. With our service, you can eliminate the complexities of managing remote staff and focus on what truly matters – growing your business.

            ## Malicious software protection
            Security is a top priority for businesses, and Equipped Speed Boarding is no exception. Our team of experts will install industry-leading malicious software protection on each laptop, safeguarding your sensitive data and reducing the risk of cybersecurity threats. Additionally, we enable disk encryption to further protect your company's valuable information. With Equipped Speed Boarding, you can have peace of mind knowing that your remote team members are operating in a secure digital environment.

            ## Offboarding
            Offboarding is just as important as onboarding, and Equipped Speed Boarding has you covered. Our efficient offboarding process ensures that departing employees can easily return their laptops, while our team removes all company-related data and software. This streamlined procedure minimizes disruption to your business operations and helps maintain the integrity of your company's digital assets. Trust Equipped Speed Boarding to provide a comprehensive solution for the onboarding and offboarding needs of your remote team members, so you can focus on your core business objectives.

            ## Redeploy, resell, recycle
            So many of the customers we've talk to have a Laptop Graveyard - a drawer, closet, or box full of used laptops that they keey "just in case". While these laptops sit there unused, they lose value and agae every day.

            We can pick these up, wipe the data with military-grade processes, and polish them for reuse. From there, depending on your needs and the age of the device, you can choose to have them:
            
            - 🛟 kept in reserve as a loaner or emergency laptop
            - 👩‍💻 sent to a new hire
            - 💵 sold as second hand (and you get the cash)
            - 🌱 recycled for parts and raw materials
`}</Markdown>
          </div>
        </div>
      </div>
      <AssetTrackingCTA />
    </main>
  );
}
