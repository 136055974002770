import Markdown from 'components/Markdown';
import useTitle from 'hooks/useTitle';
import Link from 'components/Link';
import { JoinAsACNCTA } from 'components/CallsToAction';

const UPNs = `# What we're building for you...
The Apple Authorized Reseller optimized for Device-as-a-Service (DaaS), ACNs and MSPs.

> Manage all your customer orders in on place. Get instant
  quotes for leases and device-as-a-service plans. Ensure
  devices are in DEP. Customer portal for self-serve purchasing.
  All-day support through Slack, iMessage, WhatsApp, phone, and email.


## You can:
* Buy, lease, and trade-in Apple products for your customers from one place
* Sell Macs and iPads on an upgrade program (24 or 36 months)
* DEP Enrollment? Handled.
* Keep track of all your customers, devices, commissions, and orders in one place

We're there for you... on Slack, WhatsApp, phone, and email.

## Features:
- Full catalog of Apple products
- Multiple distributors for stock availability
- Shipping and pickup to and from your customer or your address
- You and your customers use the same interface (custom logo and URLs. [yourname].acn.store included)
- Customers can self-serve purchase from a custom store or saved cart you create for them (the sales will be attributed to you)
- We handle the financing so your customers can purchase devices on a monthly plan
- One bill for your customers (Stripe integration connects to your account or we set one up for you)
- We also resell Google Workspace, 1Password, Vanta, Addigy, MalwareBytes, NordLayer, and more
- We can run and manage MDM & monitoring for you.

## Highlights
- Onboarding is quick and easy
- We offer purchase plans (leasing, financing, and upgrade programs)
- We offer trade-in programs
- We offer device-as-a-service plans
- We offer a full catalog of Apple products

## ACNs told us they cared most about:
- "Device financing prices visible in the store"
- "All purchases are enrolled into ABM"
- "Ability to create/save custom carts"

They also wanted
- an easy platform to purchase through
- a nice service to be able to offer their clients

You have more to add? Please [let us know!](/contact).

## And we understand:
- they're **your** customers
- you can always opt out or add devices not purchased through us
- you choose which features you want to use
- you choose if you want to manage the accounts yourself or if you want to give your customers direct access

`;

export default function ACNHome() {
  useTitle('ACNs | Equipped');
  return (
    <main>
      <div className="relative">
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 lg:w-full lg:max-w-2xl">
            <svg
              className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="0,0 90,0 50,100 0,100" />
            </svg>

            <div className="relative px-6 py-24 sm:py-28 lg:px-8 lg:py-24 lg:pr-0">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                <div className="mb-10 sm:flex">
                  <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                    We're looking for early adopters.{' '}
                    <a
                      href="/users/sign_up?ref=ACNs&success_url=/accounts/new_acn"
                      className="whitespace-nowrap font-semibold text-indigo-600"
                    >
                      <span className="absolute inset-0" aria-hidden="true" />
                      Join free <span aria-hidden="true">&rarr;</span>
                    </a>
                  </div>
                </div>
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  Equipped for ACNs and MSPs
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  The Apple Authorized Reseller optimized for
                  Device-as-a-Service (DaaS), ACNs and MSPs.
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <a
                    href="/users/sign_up?ref=ACNs&success_url=/accounts/new_acn"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Get started
                  </a>
                  <Link
                    href="/"
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    Learn more <span aria-hidden="true">→</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="lg:aspect-auto aspect-[3/2] object-cover lg:h-full lg:w-full"
            src="/photo-1521737711867-e3b97375f902.avif"
            alt=""
          />
        </div>
      </div>

      <div className="mx-auto max-w-7xl p-4 text-base leading-7 text-gray-700 lg:px-8">
        <Markdown className="prose max-w-none lg:prose-xl">{UPNs}</Markdown>
      </div>

      <JoinAsACNCTA />
    </main>
  );
}
