import { useState } from 'react';
import { getCookie, setCookie } from 'typescript-cookie';

/**
 * Custom hook to retrieve and store cookies for our application.
 * @param {String} key The key to store our data to
 * @param {String} defaultValue The default value to return in case the cookie doesn't exist
 */
const useCookie = (key, defaultValue) => {
  const getTheCookie = () => {
    if (typeof document === 'undefined') return defaultValue;
    return getCookie(key) || defaultValue;
  };
  const [cookie, setTheCookie] = useState(getTheCookie());

  const updateCookie = (value, props) => {
    setCookie(key, value, props);
    setTheCookie(value);
  };
  return [cookie, updateCookie];
};
export default useCookie;
