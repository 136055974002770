/*
### width `{Number}`

Defaults to `300`, the default width of SVG elements in most browsers.

### height `{Number}`

Defaults to `150`, the default height of SVG elements in most browsers.

### text `{String}`

The text to display. Defaults to the image dimensions.

### fontFamily `{String}`

The font to use for the text. For data URIs, this needs to be a system-installed font. Defaults to `'sans-serif'`.

### fontWeight `{String}`

Defaults to `'bold'`.

### fontSize `{Number}`

Defaults to 20% of the shortest image dimension, rounded down.

### dy `{Number}`

Adjustment applied to [the `dy` attribute](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/dy) of the text element so it will appear vertically centered. Defaults to 35% of the `fontSize`.

### bgColor `{String}`

The background color of the image. Defaults to `#ddd`.

### textColor `{String}`

The color of the text. For transparency, use an `rgba` or `hsla` color value. Defaults to `rgba(0,0,0,0.5)`.

### dataUri `{Boolean}`

If `true`, the function will return an encoded string for use as an `img` element's `src` value. If `false`, the function will return the unencoded SVG source. Defaults to `true`.

### charset `{String}`

Defaults to `UTF-8`, but if your source HTML document's character set is different, you may want to update this to match.} param0 
*/
export default function ImagePlaceholder({
  width = 300,
  height = 150,
  text = `${width} × ${height}`,
  fontFamily = 'sans-serif',
  fontWeight = 'bold',
  fontSize = Math.floor(Math.min(width, height) * 0.2),
  dy = fontSize * 0.35,
  bgColor = '#ddd',
  textColor = 'rgba(0,0,0,0.5)',
  className = '',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      className={className}
    >
      <rect fill={bgColor} width={width} height={height} />
      <text
        fill={textColor}
        font-family={fontFamily}
        font-size={fontSize}
        dy={dy}
        font-weight={fontWeight}
        x="50%"
        y="50%"
        text-anchor="middle"
      >
        {text}
      </text>
    </svg>
  );
}

export function AnimatedImagePlaceholder({
  width = '100%',
  height = '100%',
  text = `${width} × ${height}`,
  fontFamily = 'sans-serif',
  fontWeight = 'bold',
  fontSize = Math.floor(Math.min(width, height) * 0.2),
  dy = fontSize * 0.35,
  textColor = 'rgba(0,0,0,0.5)',
}) {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      style={{ borderRadius: '20px' }}
    >
      <defs>
        <linearGradient id="a" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset={0} stopColor="#eee" stopOpacity={0.5} />
          <stop offset={0.5} stopColor="#ccc" stopOpacity={0.5} />
          <stop offset={1} stopColor="#eee" stopOpacity={0.5} />
        </linearGradient>
        <linearGradient id="b" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset={0} stopColor="#ccc" stopOpacity={0.5} />
          <stop offset={0.5} stopColor="#eee" stopOpacity={0.5} />
          <stop offset={1} stopColor="#ccc" stopOpacity={0.5} />
        </linearGradient>
      </defs>
      <text
        fill={textColor}
        font-family={fontFamily}
        font-size={fontSize}
        dy={dy}
        font-weight={fontWeight}
        x="50%"
        y="50%"
        text-anchor="middle"
      >
        {text}
      </text>
      <rect
        id="loaderrect1"
        width="100%"
        height="100%"
        x="-200%"
        y={0}
        fill="url(#a)"
        fillOpacity={1}
        fillRule="evenodd"
      />
      <rect
        id="loaderrect2"
        width="100%"
        height="100%"
        x="-100%"
        y={0}
        fill="url(#b)"
        fillOpacity={1}
        fillRule="evenodd"
      />
      <rect
        id="loaderrect3"
        width="100%"
        height="100%"
        x={0}
        y={0}
        fill="url(#a)"
        fillOpacity={1}
        fillRule="evenodd"
      />
      <animate
        xlinkHref="#loaderrect1"
        attributeName="x"
        from="-200%"
        to="0%"
        dur="0.8s"
        begin="0s"
        fill="freeze"
        repeatCount="indefinite"
      />
      <animate
        xlinkHref="#loaderrect2"
        attributeName="x"
        from="-100%"
        to="100%"
        dur="0.8s"
        begin="0s"
        fill="freeze"
        repeatCount="indefinite"
      />
      <animate
        xlinkHref="#loaderrect3"
        attributeName="x"
        from="0%"
        to="200%"
        dur="0.8s"
        begin="0s"
        fill="freeze"
        repeatCount="indefinite"
      />
    </svg>
  );
}

export function dataImagePlaceholder({
  width = 300,
  height = 150,
  text = `${width} × ${height}`,
  fontFamily = 'sans-serif',
  fontWeight = 'bold',
  fontSize = Math.floor(Math.min(width, height) * 0.2),
  dy = fontSize * 0.35,
  bgColor = '#ddd',
  textColor = 'rgba(0,0,0,0.5)',
  dataUri = true,
  charset = 'UTF-8',
} = {}) {
  const str = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">
    <rect fill="${bgColor}" width="${width}" height="${height}"/>
    <text fill="${textColor}" font-family="${fontFamily}" font-size="${fontSize}" dy="${dy}" font-weight="${fontWeight}" x="50%" y="50%" text-anchor="middle">${text}</text>
  </svg>`;

  // Thanks to: filamentgroup/directory-encoder
  const cleaned = str
    .replace(/[\t\n\r]/gim, '') // Strip newlines and tabs
    .replace(/\s\s+/g, ' ') // Condense multiple spaces
    .replace(/'/gim, '\\i'); // Normalize quotes

  if (dataUri) {
    const encoded = encodeURIComponent(cleaned)
      .replace(/\(/g, '%28') // Encode brackets
      .replace(/\)/g, '%29');

    return `data:image/svg+xml;charset=${charset},${encoded}`;
  }

  return cleaned;
}
