import { Routes, Route } from 'react-router-dom';

import { Layout, ShopLayout } from './layouts/Layout';

import HomeTest from './components/HomeTest';
import ACNs from 'pages/ACNs';
import ErrorPage from './components/ErrorPage';
import AllInOne from 'pages/AllInOne';
import { HomeAlt } from './components/HomeAlt';
import Privacy from 'pages/Privacy';
import Done from 'pages/TheDoneCompany';
import About from 'pages/About';
import Contact from 'pages/Contact';
import Blog from 'pages/Blog';
import Post from 'pages/Post';
import Home from 'pages/Home';
import AppleDevices from 'pages/AppleDevices';
import Onboarding from 'pages/Onboarding';
import TechOps from 'pages/TechOps';
import Software from 'pages/Software';
import Compliance from 'pages/Compliance';
import Pricing from 'pages/Pricing';
import FAQs from 'pages/FAQs';
import Security from 'pages/Security';
import Terms from 'pages/Terms';
import Docs from 'pages/Docs';
import Doc from 'pages/Doc';
import Partners from 'pages/Partners';
import Partner from 'pages/Partner';
import Subscribe from 'pages/Subscribe';
import JoinWaitlist from 'pages/JoinWaitlist';
import EarlyAccess from 'pages/EarlyAccess';
import ACNSignup from 'pages/ACNSignup';
import Shop from 'pages/Shop';

export const StaticRoutes = () => (
  <Routes>
    <Route element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="index.html" element={<Home />} />
      <Route path="pricing" element={<Pricing />} />
      <Route path="apple-devices" element={<AppleDevices />} />
      <Route path="onboarding" element={<Onboarding />} />
      <Route path="compliance" element={<Compliance />} />
      <Route path="software" element={<Software />} />
      <Route path="tech-ops" element={<TechOps />} />
      <Route path="about" element={<About />} />
      <Route path="contact" element={<Contact />} />
      <Route path="privacy" element={<Privacy />} />
      <Route path="security" element={<Security />} />
      <Route path="terms" element={<Terms />} />
      <Route path="faq" element={<FAQs />} />
      <Route path="blog">
        <Route index element={<Blog />} />
        <Route path=":postId" element={<Post />} />
      </Route>
      <Route path="docs">
        <Route index element={<Docs />} />
        <Route path=":docId" element={<Doc />} />
      </Route>
      <Route path="partners">
        <Route index element={<Partners />} />
        <Route path=":partnerId" element={<Partner />} />
      </Route>

      {/* ACNs */}
      <Route path="ACNs">
        <Route index element={<ACNs />} />
        <Route path="signup" element={<ACNSignup />} />
      </Route>

      {/* Signups */}
      <Route path="early-access" element={<EarlyAccess />} />
      <Route path="email">
        <Route path="signup" element={<Subscribe />} />
      </Route>

      {/* For testing only */}
      <Route path="alt-home" element={<HomeAlt />} />
      <Route path="react-home" element={<HomeTest />} />
      <Route path="subscribe" element={<JoinWaitlist />} />
    </Route>

    <Route element={<ShopLayout />}>
      <Route path="shop" element={<Shop />} />
    </Route>

    <Route path="all-in-one" element={<AllInOne />} />
    <Route path="done" element={<Done />} />
    <Route
      path="*"
      element={<ErrorPage error={{ code: 404, name: 'Page not found' }} />}
    />
  </Routes>
);
