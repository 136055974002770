import { BanknotesIcon, ChartBarIcon } from '@heroicons/react/24/outline';
import Link from './Link';
import { MdLaptopMac } from 'react-icons/md';
import { FaGoogle, FaRecycle, FaRegBell } from 'react-icons/fa';
import { BsSpeedometer } from 'react-icons/bs';
import { BiRadar } from 'react-icons/bi';
import { IoEarthSharp } from 'react-icons/io5';
import Markdown from './Markdown';

const benefits = [
  {
    name: 'Always new hardware',
    description:
      'New hires get new devices. Everyone uprgades to the latest every 24, 36. or 48 months.',
    icon: MdLaptopMac,
    show: true,
  },
  {
    name: 'Mac financing',
    description:
      'Upgrade Plans. Leasing. Re-used devices. Save your cash. Pay monthly.',
    icon: BanknotesIcon,
    show: true,
  },
  {
    name: 'Software included',
    description:
      'Latest software for your team. Turned on when a new hire starts. Turned off and billing stopped on offboarding.',
    icon: FaGoogle,
    show: true,
  },
  {
    name: 'Predictable technology Costs',
    description: `Predictable cost per user per month.
    Plans available to cover all hardware and software costs per employee per month. No surprises.`,
    icon: ChartBarIcon,
    show: true,
  },
  {
    name: 'Monitor end user performance',
    description: `Measure how your team's devices are performing. Optimize your
    staff's tech and user experience and build productive teams.`,
    icon: BsSpeedometer,
    show: true,
  },
  {
    name: 'Track your assets',
    description:
      'Track all devices wherever they are. Know which person, room, or office they are assigned to.',
    icon: BiRadar,
    show: true,
  },
  {
    name: 'Trade-in your old devices',
    description:
      "Get rid of the laptop graveyard! Redeploy or sell old devices. We'll handle the logistics, wiping the data, or recycling.",
    icon: FaRecycle,
    show: true,
  },
  {
    name: 'Join the circular economy',
    description:
      'We trade-in, buy back and re-deploy machines. Lower your environmental impact with Equipped.',
    icon: IoEarthSharp,
    show: true,
  },
  {
    name: 'Professional support',
    description: `We work with and refer you to certified [Apple Consultants](https://consultants.apple.com/us).`,
    icon: FaRegBell,
    show: true,
  },
  {
    name: 'Proactive alerts',
    description: `You can set up update and security alerts and receive
    notifications to Slack or directly to your inbox.`,
    icon: FaRegBell,
    show: false,
  },

  /*
Always new hardware
- For those.
Software included
Get compliant pain-free
Protect your data
-
Track your assets
- Device management. Inventory. Reporting. Support.


Mac financing
- Upgrade Plans. Leasing. Save your cash
Stay current
- 24/36 upgrades. Migration.
Onboard automagically
Offboard effortlessly
Software included

Custom team catalog
Predictable tech costs
All tech on one bill.
Compliance-ready Macs

Store. Redeploy. Sell.
Device Management
Trade-Ins
Tech Ops & Support
Google Workspace
Slack
1Password
Vanta
*/

  {
    name: 'Compliance-ready Macs',
    description: '',
    icon: '',
  },

  {
    name: 'Get compliant pain-free',
    description: '',
    icon: '',
  },
  {
    name: 'Protect your data',
    description:
      'Anti-virus/mal/spy/ransomware. Backups. Encryption. Device management.',
    icon: '',
  },
  {
    name: 'Stay current',
    description: '24/36 upgrades. Migration.',
    icon: '',
  },
  {
    name: 'Onboard automagically',
    description: '',
    icon: '',
  },
  {
    name: 'Offboard effortlessly',
    description: '',
    icon: '',
  },
];
export default function Benefits() {
  return (
    <>
      <div className="mx-auto mt-28 max-w-7xl px-6 text-center lg:px-8">
        <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Everything productive teams need
        </p>
        <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
          Deliver a better tech experience to your team without having to staff
          an IT department or operate different tools yourself.
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {benefits
              .filter((item) => item.show === true)
              .map((benefit, index) => {
                const Icon = benefit.icon || ChartBarIcon;
                return (
                  <div key={index} className="flex pt-6">
                    <div className="flow-root rounded-xl border px-6 pb-8 shadow-xl shadow-slate-100/50">
                      <div className="-mt-6">
                        <div>
                          <span className="inline-flex items-center justify-center rounded-xl border bg-white p-3 shadow-lg ">
                            <Icon
                              width="1em"
                              height="1em"
                              className="h-7 w-7 text-gray-800"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                        <h3 className="mt-6 text-lg font-medium tracking-tight text-gray-900">
                          {benefit.name}
                        </h3>
                        <Markdown className="mt-5 text-base text-gray-500">
                          {benefit.description}
                        </Markdown>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="mt-12 flex items-center justify-center">
        <Link
          className="text-md rounded-md px-5 py-2 font-medium text-neutral-600 hover:bg-gray-100"
          href="/all-in-one/"
        >
          Read more about our all-in-one offer
        </Link>
      </div>
    </>
  );
}
