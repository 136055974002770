import useTitle from 'hooks/useTitle';
import { posts } from 'data/blog';
import Link from 'components/Link';

export default function Blog() {
  useTitle('Blog | Equipped');
  return (
    <>
      <div className="scene mb-10">
        <div className="mx-auto mb-20 flex max-w-3xl flex-col">
          <div className="mx-auto flex max-w-4xl flex-col items-center justify-center py-20 text-center">
            <h1 className="mb-5 text-5xl md:text-6xl xl:text-7xl">
              The Equipped Blog
            </h1>
            <p className="xl:text-2xl">
              Learn more about tech ops best practices, how to improve staff
              productivity and user experience and how to use our products and
              services effectively.
            </p>
          </div>

          <ul className="mx-auto grid w-full max-w-4xl list-none grid-cols-1 gap-y-10 py-5">
            {posts.map((post, index) => (
              <li key={index}>
                <div className="shadow-colored-2xl rounded-xl border p-6 transition-shadow">
                  <Link
                    className="flex h-full flex-col items-start justify-between text-gray-900 hover:no-underline"
                    href={`/blog/${post.id}`}
                  >
                    <h2 className="mb-5 break-words text-xl lg:text-3xl">
                      {post.title}
                    </h2>
                    <p className="mb-7 break-words leading-6 text-gray-500">
                      {post.description}
                    </p>
                    <p className="text-sm text-gray-500">{post.date}</p>
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
